import React, { useState } from "react";
import img from "../../Images/listing/Rectangle_185.png";
import img_2 from "../../Images/listing/Ellipse 374.png";
import whatsapp from "../../Images/listing/whatsapp.png";
import { IoIosCall } from "react-icons/io";
import { teal } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { FaCaretDown } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { BsPrinter } from "react-icons/bs";
import { CiHeart, CiLocationOn } from "react-icons/ci";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function Banner() {
    const [selectedValue, setSelectedValue] = React.useState('e');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });
    const [fieldErrors, setFieldErrors] = React.useState({})
    const [data, setData] = useState({
        email: "",
        password: "",
        name: "",
        contact: "",
    })
    const validateStep = () => {
        let errors = {

        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }

        if (!data.contact) {
            errors.contact = "Contact is required.";
        }
        else if (!isValidPakistaniPhoneNumber(data.contact)) {
            errors.contact = "Please enter a valid Pakistan phone number.";
        }
        setFieldErrors(errors);
        
        return Object.keys(errors).length === 0;
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const formatPakistaniPhoneNumber = (phoneNumber) => {

        let phone = phoneNumber.replace(/\D/g, ''); 

       
        if (phone.startsWith('92')) {
            phone = phone.slice(2);
        } else if (phone.startsWith('0')) {
            phone = phone.slice(1);
        }

        if (phone.length === 10) {
            return `+92${phone}`;
        }

        return phoneNumber; 
    };
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

        
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
       
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

        return isValid;
    };
    const submit = (event) => {
        event.preventDefault();
        if (validateStep()) {
            console.log("hello");
        }
    }
    return (
        <div className="row">
            <div className="col-lg-8 col-12 my-2">
                <div className="listing_main_img">
                    <img src={img} alt="" />
                </div>
                <div className="listing_main p-3">
                    <div className="d-flex justify-content-between">
                    <h1>Beverly Springfield</h1>
                    <div className="detail-icon">
                        <div><CiHeart /></div>
                        <div><RiShareForwardLine /></div>
                        <div><BsPrinter /></div>
                    </div>
                    </div>
                    <span className="d-flex align-items-center"><CiLocationOn style={{marginRight:'5px',color:"#1C9093"}} />23H Block, Phase 8, DHA Lahore </span>
                    <h2>2.1 Crore / Year </h2>
                    <div className="property-type">
                       <div className="p">
                        <h4>Property Type</h4>
                        <h3>Warehouse</h3>
                       </div>
                       <div className="p">
                        <h4>Available Space</h4>
                        <h3>8 Marla</h3>
                       </div>
                       <div className="p">
                        <h4>Building Size</h4>
                        <h3>8 Marla</h3>
                       </div>
                       <div className="p">
                        <h4>Lease Type</h4>
                        <h3>New</h3>
                       </div>
                       <div style={{border:'none'}} className="p">
                        <h4>Availability</h4>
                        <h3>Available Now</h3>
                       </div>
                    </div>
                    <div className="about-property">
                    <h1>About Property</h1>
                    <p className="discription">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="read_more">Read More <FaCaretDown  className="mx-2"/>  </button>
                    </div>
                    <hr />
                    <div className="aminty">
                        <h1>Amenities</h1>
                        <div className="mian_feature">
                            <h3>Main features</h3>
                            <div className="row">
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Swimming pool</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Playstation</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Air conditioning</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Jacuzzi</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Fitness/Gym</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Fireplace</span>
                                </div>

                                <div className="col-lg-3 col-6 my-1">
                                    <span>Wireless Internet</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Satellite / Cable</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Soaps</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Completely enclosed</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Ground floor bedroom</span>
                                </div>
                                <div className="col-lg-3 col-6 my-1">
                                    <span>Living room</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <button className="read_more">Read More <FaCaretDown className="mx-2" />  </button>
                    </div>
                    <h1>Location & Nearby</h1>
                    <h3>Society Map <span className="mx-2">Nearby</span></h3>
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13604.362497224101!2d74.42700851609969!3d31.521670828933576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190f0edb1ad977%3A0xf6b165821d6a6dc!2sDHA%20Phase%208%20-%20Ex%20Park%20View%20Park%20View%20CHS%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1722356405588!5m2!1sen!2s" width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                 
                </div>
            </div>
            <div className="col-lg-4 col-12 my-2">
                <div className="logo_prop p-3">
                    <div className="logo_info">
                        <div className="logo_img">

                            <img src={img_2} alt="" />
                        </div>

                        <p className="heading_info">Al Karam Property</p>
                        <p className="content_info">
                            Ehsaan Elahi
                            <br />
                            Agent ID: 50402718
                        </p>
                        <div className="info_btns">
                            <button><IoIosCall className="me-2" />Call</button>
                            <button><img src={whatsapp} alt="" className="me-2" />WhatsApp</button>
                        </div>

                    </div>
                    <form onSubmit={submit}>
                        <div className='inp_login'>
                            <label className="logo_label">Name</label>
                            <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Name' />
                            {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}        </div>
                        <div className='inp_login'>
                            <label className="logo_label">Email</label>
                            <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>

                        <div className='inp_login'>
                            <label className="logo_label">Contact</label>
                            <div className='contact_contry'>
                                <div className='img_container'>
                                    <img src='./image/Group.png' alt='...' />
                                    <span>+92</span>
                                </div>
                                <input style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} onChange={(e) => setData({ ...data, contact: formatPakistaniPhoneNumber(e.target.value) })} type='number' placeholder='e.g 3324751060' />
                            </div>
                            {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                        </div>
                        <div className='inp_login'>
                            <label className="logo_label">Message</label>
                            <textarea style={{ minHeight: "100px", width: "100%", border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem" }} onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Write your message.....' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>
                        <div className='form_radio'>
                            <label className="logo_label">I am a:</label>
                            <Radio
                                {...controlProps('e')}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Buyer / Tenant</span>
                            <Radio
                                {...controlProps('f')}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Agent</span>
                            <Radio
                                {...controlProps('g')}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Other</span>
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>
                        <div className='inp_login px-2'>
                            <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Investor' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>
                        <div className='form_radio'>
                            <Checkbox
                                {...label}
                                defaultChecked
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <label className="logo_label">Keep me informed about similar properties.</label>
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>
                        <button type='submit' className='Sign_inBtn py-3'>Send Email</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Banner;
