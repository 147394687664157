import React, { useEffect, useState } from 'react'
import Profile from '../Component/UserSetting/Profile'
import Preferences from '../Component/UserSetting/Preferences'
import ChangePassword from '../Component/UserSetting/ChangePassword'
import { useParams } from 'react-router-dom'

function UserSetting() {
    const [lable,setLable]=useState("profile")
    const parms=useParams()
        useEffect(()=>{
            if(parms?.id){
                setLable("preferences")
            }
        },[parms?.id])
    return (
        <div className='add_propertey_main user_setting'>
            <div className="heading">User Setting</div>
            <div className='row'>
                <div className='col-lg-3 col-12'>
                    <div className='locationPurpos p-0 py-2'>
                        <ul className='d-lg-block d-flex align-items-center justify-content-sm-start justify-content-center ps-sm-3 ps-0 mb-lg-3 mb-0' style={{gap:'30px'}}>
                            <li style={{border:"none"}} onClick={()=>setLable("profile")} className={`${lable==="profile"&&"active_tab"}`}>Profile</li>
                            <li style={{border:"none"}} onClick={()=>setLable("preferences")} className={`${lable==="preferences"&&"active_tab"}`}>Preferences</li>
                            <li style={{border:"none"}} onClick={()=>setLable("Change Password")} className={`${lable==="Change Password"&&"active_tab"}`}>Change Password</li>
                        </ul>
                    </div>
                </div>
                <div className='col-lg-9 col-12 agency_staff'>
                <div className='content_box'>
                      {lable==="profile"&&<Profile/>}
                      {lable==="preferences"&&<Preferences lable={lable}/>}
                      {lable==="Change Password"&&<ChangePassword/>}
                 
                </div>
                </div>
            </div>
        </div>
    )
}

export default UserSetting