import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Profile from './Pages/Profile';
import MiniDrawer from './AgentDashBorad/SideBar/MiniDrawer';
import Contactus from './Pages/Contactus';
import Lease from './Pages/Lease';
import LeasDetail from './Pages/LeasDetail';
import HousingSociety from './Pages/HousingSociety';
import InstantValue from './Pages/InstantValue';
import AboutUs from './Pages/AboutUs';
import Agents from './Pages/Agents';
import AgentsProfile from './Pages/AgentsProfile';
import InstantProperty from './Pages/InstantProperty';
import Blogs from './Pages/Blogs';
import Blogsingle from './Pages/Blogsingle';
import ListingProperty from './Pages/Listingproperty';
import News from './Pages/News';
import Newssingle from './Pages/Newssingle';
import HousingSocietyDetails from './Pages/HousingSocietyDetailsExp';
import { useAuth } from './Context/ContextProvider';
import { useEffect } from 'react';
import ScrollToTop from './Camponent/NavBarFooter/ScrollToTop';
import TermsCondition from './Pages/TermsCondition';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import AgencyList from './Pages/AgencyList';
import ProjectListing from './Pages/ProjectListing';
import Faqs from './Pages/Faqs';
function App() {
  const { currencyData, areaUnitData, 
    setProjectType,
    setcityData,
    getTypePublic,
    getcateGoryPublic,
    projectType,
    getCity,
    setType,
    setCurrentCity,
    setCategory,
    setsub_category,
    setallcity, } = useAuth()
 
    useEffect(() => {
      let isMounted = true; // Track if the component is mounted
      const abortController = new AbortController(); // For fetch cancellation
  
      const fetchData = async () => {
        try {
          // Fetch property types
          const typeResult = await getTypePublic();
          if (typeResult?.success) {
            const propertyTypes = typeResult.data.data.property_types;
            setType(propertyTypes);
          }
  
          // Fetch project types
          const projectResult = await projectType();
          if (projectResult?.success) {
            const projectTypes = projectResult.data.data.project_types;
            setProjectType(projectTypes);
            if(projectTypes?.length){
            let categoryResponse= await getcateGoryPublic(projectTypes[0]?.id)
            if(categoryResponse?.success){
              
              const categories = categoryResponse?.data?.data?.categories;
             setCategory(categories);
             if(categories[0]?.sub_categories?.length){
               setsub_category(categories[0].sub_categories)
             }

            }
            }
          }
  
          // Fetch city data
          const cityResult = await getCity();
          currencyData();
          areaUnitData();
          if (cityResult?.success) {
            const cityData = cityResult.data;
            setcityData(cityData);
            setallcity(cityData);
  
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const lat = position.coords.latitude;
                  const lon = position.coords.longitude;
                  fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`, {
                    signal: abortController.signal,
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      if (isMounted && data?.address?.district) {
                        const city = data?.address?.district?.split(" ")[0];
                        cityData.forEach((item) => {
                          if (item?.city === city) {
                            setCurrentCity({ city_name: item.city, app_code: item?.app_code });
                            // fetchData(item?.app_code); Uncomment if needed
                          }
                        });
                      }
                    })
                    .catch((err) => {
                      if (err.name !== "AbortError") {
                        console.error("Fetch error:", err);
                      }
                    });
                },
                (err) => {
                  console.error("Geolocation error:", err);
                }
              );
            }
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false; // Mark component as unmounted
        abortController.abort(); // Cancel ongoing fetch requests
      };
    }, []); 
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/profile/:id' element={<Profile />} />
        <Route path='/contact' element={<Contactus />} />
        <Route path='/Lease' element={<Lease />} />
        <Route path='/Lease-detail' element={<LeasDetail />} />
        <Route path='/project/:id' element={<HousingSociety />} />
        <Route path='/intstant-valuation' element={<InstantValue />} />
        <Route path='/intstant-property' element={<InstantProperty />} />
        <Route path='/Blogs' element={<Blogs />} />
        <Route path='/Blogsingle' element={<Blogsingle />} />
        <Route path='/listing-property' element={<ListingProperty />} />
        <Route path='/agent-list/:id' element={<AgencyList />} />
        <Route path='/agent-list' element={<AgencyList />} />
        <Route path='/listing-property/:id' element={<ListingProperty />} />
        <Route path='/News' element={<News />} />
        <Route path='/Newssingle' element={<Newssingle />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/agents' element={<Agents />} />
        <Route path='/project-listing' element={<ProjectListing />} />
        <Route path='/agent-profile/:id' element={<AgentsProfile />} />
        <Route path='/project-detail/:id' element={<HousingSocietyDetails />} />
        <Route path='/agent/*' element={<MiniDrawer />}></Route>
        <Route path='/term-and-condition' element={<TermsCondition />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/faqs' element={<Faqs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
