import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import NeighborhoodProperties from '../Camponent/HomePage/NeighborhoodProperties'
import Banner from '../Camponent/Housingsocity/Banner'
import Category from "../Camponent/Housingsocity/Catogery"
import Footer from '../Camponent/NavBarFooter/Footer'
import TopProject from '../Camponent/Housingsocity/TopProject'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider'

function HousingSociety() {
  const parms=useParams()
  const {projectData}=useAuth()
  const [projectList,setProjectList]=useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await projectData(parms?.id);
        if (result?.success) {
          const propertyTypes = result.data.data.projects;
          setProjectList(propertyTypes);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData()

    // eslint-disable-next-line
  }, [parms?.id])
  return (
    <div>
      <NavBar/>
      <Banner/>
      <TopProject projectList={projectList}/>
      <NeighborhoodProperties/>
      {/* <Category/> */}
      <Footer/>
    </div>
  )
}

export default HousingSociety
