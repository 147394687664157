import React from 'react'
import Banner from '../Camponent/ProjectListing/Banner'
import ListingProject from '../Camponent/ProjectListing/ListingProject'
import Footer from '../Camponent/NavBarFooter/Footer'

function ProjectListing() {
  return (
    <div>
        <Banner/>
        <ListingProject/>
        <Footer/>
    </div>
  )
}

export default ProjectListing