import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/AgencyList/Banner'
import ListAgents from '../Camponent/AgencyList/ListAgents'
import Footer from '../Camponent/NavBarFooter/Footer'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider'

function AgencyList() {
  const { agencyList,getCity,getTypePublic,featureAgencyList, setFeatureAgencyList } = useAuth()
  const [cityList, setcityList] = useState([])
  const [currentCity,setCurrentCity]=useState({
    city_name:"",
    city_code:""
  })
  const [isSearch,setIsSearch]=useState(false)
  const [type,setType]=useState([])
  const parms=useParams()
  useEffect(() => {
      
      const fetchCart = async () => {
          
          try {
              let result = await getCity();
              if (result?.success) {
                  let propertyData = result?.data;
                  setcityList(propertyData)
                  if(parms?.id){
                    propertyData?.map((item)=>{
                      if(item?.city===parms?.id){
                        setCurrentCity({
                          city_code:item?.app_code,
                          city_name:item?.city
                        })
                        fetchCart1(item?.app_code)
                        setIsSearch(true)
                      }
                    })
            
                  }
                  else if(!featureAgencyList?.length){
                    fetchCart1()
                  }
              
              }
          } catch (error) {
              console.error("An error occurred while fetching data:", error);
          }
      };
      const fetchCart1 = async (id) => {
          
          try {
              let result = await agencyList({city_code:id});
              if (result?.success) {
                  let propertyData = result?.data?.data?.agencies;
                  setFeatureAgencyList(propertyData)

              }
          } catch (error) {
              console.error("An error occurred while fetching data:", error);
          }
      };
      const fetchData = async () => {
        try {
          let result = await getTypePublic();
          if (result?.success) {
            const propertyTypes = result.data.data.property_types;
            setType(propertyTypes);
  
        
     
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
        }
      };
      fetchCart()
      fetchData()
      
  }, [])
  const searchData = async (dataValue,city) => {
          
    try {
        let result = await agencyList(dataValue);
        if (result?.success) {
            let propertyData = result?.data?.data?.agencies;
            setFeatureAgencyList(propertyData)
            setCurrentCity({...currentCity,city_name:city,city_code:dataValue?.city_code})
            setIsSearch(true)
        }
    } catch (error) {
        console.error("An error occurred while fetching data:", error);
    }
};
  return (
    <div>
        <NavBar/>
        <Banner id={parms?.id} isSearch={isSearch} setIsSearch={setIsSearch} searchData={searchData} type={type} allcity={cityList} currentCity={currentCity} setCurrentCity={setCurrentCity}/>
        <ListAgents featureAgencyList={featureAgencyList} currentCity={currentCity}/>
        <Footer/>
    </div>
  )
}

export default AgencyList