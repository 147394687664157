import React, { useEffect, useRef, useState } from "react";

import img from '../../Images/dummy/placeholder.png'
import img1 from '../../Images/Frame 1300192747.png'
import img2 from '../../Images/Frame 1300192750.png'
import flag from '../../Images/listing/pak.png'
import img3 from '../../Images/AgentProfile/Group 1000014158.png'
import img_2 from "../../Images/listing/Ellipse 374.png";
import { IoDiamond } from 'react-icons/io5'
import { CiLocationOn } from 'react-icons/ci'
// import {  FaShareAlt } from 'react-icons/fa'
import { MdArrowOutward } from 'react-icons/md'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import whatsapp from "../../Images/listing/whatsapp.png";
import { IoIosCall } from "react-icons/io";
import { FallbackImage } from "../FallbackImage";
import Call from "../profile/Call";
import { useAuth } from "../../Context/ContextProvider";
import { useParams } from "react-router-dom";


function Profile({ agencyData }) {
    const {agencyInquery}=useAuth()

    const [fieldErrors, setFieldErrors] = React.useState({})
    const [data, setData] = useState({
        email: "",
        password: "",
        name: "",
        contact: "",
    })
    const validateStep = () => {
        let errors = {

        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!data.subject) {
            errors.subject = "Subject is required.";
        }

        if (!data.contact) {
            errors.contact = "Contact is required.";
        }
        else if (!isValidPakistaniPhoneNumber(data.contact)) {
            errors.contact = "Please enter a valid Pakistan phone number.";
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const formatPakistaniPhoneNumber = (phoneNumber) => {

        let phone = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

        // Handle different input formats
        if (phone.startsWith('92')) {
            phone = phone.slice(2);
        } else if (phone.startsWith('0')) {
            phone = phone.slice(1);
        }

        if (phone.length === 10) {
            return `+92${phone}`;
        }

        return phoneNumber; // Return original input if it doesn't match the expected formats
    };
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters except '+'
        // eslint-disable-next-line
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

        // Log the cleaned phone number
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
        // Regular expression for Pakistani phone number
        // const pakistaniPhoneRegex = /^\+92\d{11}$/;

        // Validate phone number format and length
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

        return isValid;
    };
    const parms=useParams()
    const submit = (event) => {
        event.preventDefault();
        if (validateStep()) {
            agencyInquery({...data,id:parms?.id});
        }
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isClamped, setIsClamped] = useState(false);
    const [isClamped1, setIsClamped1] = useState(false);
    const textRef = useRef(null);
    const textRef1 = useRef(null);

    useEffect(() => {
        // This checks if the text is overflowing after the initial render
        const checkClamping = () => {
            if (textRef.current) {
                setIsClamped(textRef.current.scrollHeight > textRef.current.offsetHeight);
            }
              if (textRef1.current) {
                setIsClamped1(textRef1.current.scrollHeight > textRef1.current.offsetHeight);
            }
        };

        // Check for clamping when the component mounts or whenever agencyData changes
        checkClamping();

        // Optionally, you can also check again if the description changes dynamically
        // after the initial render (if agencyData is updated).
        const observer = new MutationObserver(checkClamping);
        if (textRef.current) {
            observer.observe(textRef.current, { childList: true, subtree: true });
        }

        if (textRef1.current) {
            observer.observe(textRef1.current, { childList: true, subtree: true });
        }

        return () => {
            if (observer) observer.disconnect(); // Clean up observer
        };
    }, [agencyData]);

    const handleToggleReadMore = () => {
        setIsExpanded((prev) => !prev);
    };
    const handleToggleReadMore1 = () => {
        setIsExpanded1((prev) => !prev);
    };

    const [open1, setOpen1] = useState(false);

    const handleOpen = () => setOpen1(true);

 

    return (
        <>
            <div className="container-fluid mt-4 px-3">
                <div className="row px-4">
                    <div className="col-lg-8 col-12 p-0">
                        <div className="row m-0 py-3 px-2 main-row">
                            <div className="col-10 p-2 d-flex">
                                <div className="img-box">
                                    <FallbackImage style={{ width: "100px", height: "100%", objectFit: "cover", borderRadius: "5px" }} src={agencyData?.agency_profile?.agency_image} alt={"agency ptofile"} fallbackSrc={img} />
                                </div>
                                <div className="imgbox-text mx-2 px-1">
                                    <div className="first-box d-flex">
                                        <div className="heading">
                                            {agencyData?.agency_profile?.agency_name}
                                        </div>
                                        {agencyData?.agency_profile?.package&&<div className="primium-card mx-3">
                                            <IoDiamond className='mx-1' /> {agencyData?.agency_profile?.package?.name}
                                        </div>}
                                    </div>
                                    <div className="second-box my-1">
                                        <div className="location">
                                            <span><CiLocationOn /> </span>  Lahore
                                        </div>
                                    </div>
                                    <div className="third-box d-flex">
                                        <div className="house-box" style={{ marginRight: "16px" }}><span><img src={img1} alt="" /></span>{agencyData?.agency_profile?.sell_count} for Sale</div>
                                        <div className="house-box" style={{ marginRight: "16px" }}><span><img src={img2} alt="" /></span>{agencyData?.agency_profile?.rent_count} for Rent</div>
                                        <div className="house-box"><span><img src={img2} alt="" /></span>{agencyData?.agency_profile?.lease_count} for Lease</div>

                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-2 col-12 pt-1 d-flex justify-content-lg-start align-items-start  justify-content-end ">
                                <button className='btn share-btn my-1' style={{ marginLeft: "8px" }}> <FaShareAlt /> Share</button>
                            </div> */}
                        </div>

                        <div className="row second-row px-0 mx-0 mt-4 pt-1">
                            <div className="col-12 p-0 m-0">
                                <div className="properties">
                                    Properties By {agencyData?.agency_profile?.agency_name}
                                </div>
                            </div>
                        </div>

                        <div className="row third-row p-0 m-0">
                            {agencyData?.total_properties?.map((item) => {
                                return (
                                    <div className="col-md-6 col-12  py-2 special-col" style={{ paddingRight: "12px", paddingLeft: "0" }}>
                                        <div className="house-card pb-2">
                                            <div className="house-box d-flex justify-content-between align-items-center px-2 py-2">
                                                <div className="house-img d-flex align-items-center">
                                                    <span> <img src={img1} alt="" /></span>
                                                    {item?.total} for {item?.name === "Sell" ? "Sale" : item?.name}
                                                </div>
                                                {/* <div className="view d-flex align-items-center">
                                                    <a href="/">View All <MdArrowOutward /></a>
                                                </div> */}
                                            </div>
                                            <div className="card-details d-flex flex-wrap px-1 my-1">
                                                {item?.categories?.map((items) => {
                                                    if (items?.total !== 0) {
                                                        return (
                                                            <div className="details-box mx-1 mt-2">
                                                                <div className="quantity">{items?.total}</div>
                                                                <div className="house-type">{items?.name}</div>
                                                            </div>
                                                        )
                                                    }
                                                    return null
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>

                        {/* <div className="row forth-row px-0 mx-0 mt-3 pt-1">
                            <div className="col-12 p-0 m-0">
                                <div className="team">
                                    Agency Team
                                </div>
                            </div>
                        </div> */}


                        {/* <div className="row mx-0 mt-3 p-0 d-flex justify-content-md-start justify-content-center">
                            <div className="col-md-3 col-8 p-0 ">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img className='shadow-img' src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">CEO</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-8 p-0 mx-3 my-md-0 my-4">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">Plots Agent</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-8 p-0 ">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">Home Agent</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row fifth-row mt-4 pt-3 mx-0 px-0">
                            <div className="col-8 p-0">
                                <div className="agency">
                                    About Agency
                                </div>
                            </div>

                            <div
                                ref={textRef}
                                className={`agency-text ${isExpanded ? "expanded" : "clamped"}`}
                                style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: isExpanded ? "none" : 3,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                }}
                            >
                                {agencyData?.agency_profile?.description}
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, provident praesentium? Esse eius vero consectetur odio laudantium aperiam molestias itaque culpa rerum quos quo fugiat, iste aspernatur tenetur ut exercitationem explicabo, harum earum magni corrupti, temporibus incidunt magnam ipsa? Magnam, esse tenetur! Quod ad illo quaerat neque voluptatem. Sit repellat at cum? Vel veritatis sed animi reprehenderit atque nulla laboriosam quod corporis ducimus, enim explicabo! Mollitia voluptas atque necessitatibus molestiae quo nihil eaque quod, cumque, exercitationem error quis, maxime sed tempora asperiores perferendis ducimus sunt incidunt! Quas aliquam veritatis minima cupiditate laborum? Nemo atque consequatur temporibus perferendis, natus reprehenderit dignissimos quia pariatur tempora corrupti quas beatae quidem laborum fuga esse repellat, quos officia ratione odio distinctio odit repellendus ut, reiciendis omnis! Dolore amet accusamus libero, quibusdam soluta dolorum quam molestias laborum deserunt. Quod minus voluptatem qui similique facere consequuntur dolorem cumque! Eveniet est delectus corrupti suscipit vel, nulla quas? Quo aperiam vel, perspiciatis esse veniam pariatur? Quisquam dolorum ducimus nam, eius quia quas. Error explicabo optio, aspernatur ullam ad tempore minus accusamus magni ipsa dolorem, quo repellat nemo debitis quis autem quos sint iste unde? Mollitia voluptas esse ipsam vero recusandae eligendi error, dolorem quod soluta nobis amet ex temporibus!
                            </div>
                            {isClamped && (
                                <div className="read-more-container d-flex justify-content-end">
                                    <div className="read-function" onClick={handleToggleReadMore}>
                                        {isExpanded ? (
                                            <>
                                                Read less <IoMdArrowDropup />
                                            </>
                                        ) : (
                                            <>
                                                Read more <IoMdArrowDropdown />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className='hr mt-4' />
                        </div>
                        <div className="row fifth-row mt-4 pt-3 mx-0 px-0">
                            <div className="col-8 p-0">
                                <div className="agency d-flex">
                                    <div className="user-image">
                                        <FallbackImage style={{width:"100px",height:"100px",borderRadius:"50%"}} src={agencyData?.agency_profile?.ceo_image} alt={"agency ptofile"} fallbackSrc={img3} />
                                    </div>
                                    <div>
                                    {agencyData?.agency_profile?.ceo_full_name} (CEO)
                                    <div
                                ref={textRef}
                                className={`agency-text ${isExpanded1 ? "expanded" : "clamped"}`}
                                style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: isExpanded1 ? "none" : 3,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                }}
                            >
                                {agencyData?.agency_profile?.ceo_desc}
                            </div>
                                    </div>
                        
                                </div>
                            </div>
                          
                            {isClamped1 && (
                                <div className="read-more-container d-flex justify-content-end">
                                    <div className="read-function" onClick={handleToggleReadMore1}>
                                        {isExpanded1 ? (
                                            <>
                                                Read less <IoMdArrowDropup />
                                            </>
                                        ) : (
                                            <>
                                                Read more <IoMdArrowDropdown />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className='hr mt-4' />
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 my-lg-0 mt-5 mb-4" style={{ paddingLeft: "26px" }}>
                        <div className="logo_prop p-3">
                            <div className="logo_info">
                                <div className="logo_img">
                                    <FallbackImage src={agencyData?.agency_profile?.agency_image} alt={"..."} fallbackSrc={img_2} />
                                </div>

                                <p className="heading_info">{agencyData?.agency_profile?.agency_name}</p>
                                <p className="content_info">
                                    {agencyData?.agency_profile?.email}

                                </p>
                                <div className="info_btns">
                                    <button onClick={() => {
                                        handleOpen()

                                    }} ><IoIosCall className="me-2" />Call</button>
                                    <a href={`https://wa.me/${agencyData?.agency_profile?.phone_number}`} target="_blank">
                                        <button><img src={whatsapp} alt="" className="me-2" />WhatsApp</button>

                                    </a>
                                </div>

                            </div>
                            <form onSubmit={submit}>
                                <div className='inp_login'>
                                    <label className="logo_label">Name</label>
                                    <input value={data?.name} onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Name' />
                                    {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}        </div>
                                <div className='inp_login'>
                                    <label className="logo_label">Email</label>
                                    <input value={data?.email} onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                    {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                                </div>
                                <div className='inp_login'>
                                    <label className="logo_label">Subject</label>
                                    <input value={data?.subject} onChange={(e) => setData({ ...data, subject: e.target.value })} type='text' placeholder='General Inquiry' />
                                    {fieldErrors?.subject && <span className='text_error'>{fieldErrors?.subject}</span>}
                                </div>

                                <div className='inp_login'>
                                    <label className="logo_label">Contact</label>
                                    <div className='contact_contry'>
                                        <div className='img_container'>
                                            <img src={flag} alt='...' />
                                            <span>+92</span>
                                        </div>
                                        <input value={data?.contact} style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} onChange={(e) => setData({ ...data, contact: formatPakistaniPhoneNumber(e.target.value) })} type='number' placeholder='e.g 3324751060' />
                                    </div>
                                    {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                                </div>
                                <div className='inp_login'>
                                    <label className="logo_label">Message</label>
                                    <textarea value={data?.message} style={{ minHeight: "100px", width: "100%", border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem" }} onChange={(e) => setData({ ...data, message: e.target.value })} type='email' placeholder='Write your message.....' />
                                    {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                                </div>
                                <button type='submit' className='Sign_inBtn py-3'>Send Email</button>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
            <Call profileData={{
                user: {
                    phone_number: agencyData?.agency_profile?.phone_number,
                    avatar: agencyData?.agency_profile?.agency_image,
                    landline: agencyData?.agency_profile?.phone_number,
                    email: agencyData?.agency_profile?.email,
                    name: agencyData?.agency_profile?.agency_name,

                }
            }} open={open1} setOpen={setOpen1} />
        </>
    )
}

export default Profile
