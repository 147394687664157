import React, { useEffect, useState } from "react";
import img from '../../Images/HousingSocietyDetailsExpand/Rectangle 1795.png'
import img1 from '../../Images/Vector (2).png'
import flag from '../../Images/listing/pak.png'
import img6 from '../../Images/Frame 1300192638.png'
import img10 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1803.png'
import img12 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1810.png'
import img14 from '../../Images/HousingSoceityDetaislIcons/Group (2).png'
import front_img from '../../Images/dummy/placeholder.png'
import modal_img from '../../Images/HousingSocietyDetailsExpand/Rectangle 1731.svg'
import icon from '../../Images/HousingSoceityDetaislIcons/044-swimming-pool.png'
import icon12 from '../../Images/HousingSoceityDetaislIcons/Frame 1300192647.png'
import icon13 from '../../Images/HousingSoceityDetaislIcons/Group.png'
import icon14 from '../../Images/logo.png'
import new2 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1733.svg'
import new3 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1735.svg'
import new4 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1736.svg'
import new5 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1737.svg'
import new6 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1738.svg'
import new7 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1739.svg'
import new8 from '../../Images/HousingSocietyDetailsExpand/Rectangle 1734.png'
import { IoClose, IoLocationOutline, IoVideocamSharp } from 'react-icons/io5'
import { FiClock } from 'react-icons/fi'
import { FaCamera, FaCheck, FaVideo } from 'react-icons/fa'
import { IoIosCall, IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { GoArrowLeft, GoArrowRight } from 'react-icons/go'
import img_2 from "../../Images/listing/Ellipse 374.png";
import whatsapp from "../../Images/listing/whatsapp.png";
import { teal } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { BsFillFlagFill } from "react-icons/bs";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FallbackImage } from "../FallbackImage";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import Call from "../profile/Call";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 580,
  border: 'none',
  outline: 'none'
};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  border: 'none',
  outline: 'none'
};

const imageArray = [
  { img: new6 },
  { img: new2 },
  { img: new3 },
  { img: new4 },
  { img: new5 },
  { img: new6 },
  { img: new7 },
  { img: new8 },
  { img: new3 },
  { img: new5 },
]


function Banner({projectData}) {
  const {projectIquiry}=useAuth()
  const [selectedValue, setSelectedValue] = React.useState('e');
  const handleChange = (event) => {
    setData({...data,type:event.target.value});
  };

  const controlProps = (item) => ({
    checked: data.type === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });
  const [fieldErrors, setFieldErrors] = React.useState({})
  const [data, setData] = useState({
    email: "",
    password: "",
    name: "",
    contact: "",
    type:"buyer/tenant"
  })
  const validateStep = () => {
    let errors = {

    };
    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.phone_number) {
      errors.contact = "Contact is required.";
    }
    else if (!isValidPakistaniPhoneNumber(data.phone_number)) {
      errors.contact = "Please enter a valid Pakistan phone number.";
    }
    setFieldErrors(errors);
    
    return Object.keys(errors).length === 0;
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const formatPakistaniPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

    
    if (phone.startsWith('92')) {
      phone = phone.slice(2);
    } else if (phone.startsWith('0')) {
      phone = phone.slice(1);
    }
    if (phone.length === 10) {
      return `+92${phone}`;
    }

    return phoneNumber; 
  };
  const isValidPakistaniPhoneNumber = (phoneNumber) => {
   
    let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

    
    console.log(`Cleaned Phone Number: "${cleanedPhoneNumber}"`);
    const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
    
    const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);
    console.log(`Is Valid: ${phoneNumber}`);

    return isValid;
  };
  const parms=useParams()
  const submit = (event) => {
    event.preventDefault();
    if (validateStep()) {
      projectIquiry({...data,id:parms?.id});
    }
  }

  function formatPrice(value) {
    if (value >= 10000000) {
        // Convert to Crore
        return (
            <>
                {(value / 10000000).toFixed(2)} <span style={{ color: "grey" }}>Cr</span>
            </>
        );
    } else if (value >= 100000) {
        // Convert to Lakh
        return (
            <>
                {(value / 100000).toFixed(2)} <span style={{ color: "grey" }}>Lakh</span>
            </>
        );
    } else if (value >= 1000) {
        // Convert to Thousand
        return (
            <>
                {(value / 1000).toFixed(2)} <span style={{ color: "grey" }}>Thousand</span>
            </>
        );
    } else {
        // Less than 1000, show as is
        return value?.toString();
    }
}

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [newOpen, setNewOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleNewOpen = () => setNewOpen(true);
  const handleClose = () => setOpen(false);
  const handleNewClose = () => setNewOpen(false);
  const [button, setButton] = useState(false)


  const months = [
    'Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024',
    'Jun 2024', 'Jul 2024', 'Aug 2024', 'Sep 2024', 'Oct 2024',
    'Nov 2024', 'Dec 2024'
  ];

  
  const [startIndex, setStartIndex] = useState(4); 

  
  const handleLeftArrowClick = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + months.length) % months.length);
  };

 
  const handleRightArrowClick = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % months.length);
  };

  
  const currentMonths = [];
  for (let i = 0; i < 6; i++) {
    currentMonths.push(months[(startIndex + i) % months.length]);
  }

 
  const currentMonth = currentMonths[0];


  const [selectedOption, setSelectedOption] = useState(null); // State to track the selected option
  const [selectedOption1, setSelectedOption1] = useState(null); // State to track the selected option

 
  const [selectFloorImg,setSelectedImageFloor]=useState()
  const handleClick = (id,imgs) => {
    setSelectedOption(id); // Update the state to reflect the selected option
    setSelectedImageFloor(imgs)
  };
  const [selectPlanImg,setSelectedImagePlan]=useState()
  const handleClick1 = (id,imgs) => {
    setSelectedOption1(id); // Update the state to reflect the selected option
    setSelectedImagePlan(imgs)
  };

  const [image, setImage] = useState(modal_img)
  const [tab, setTab] = useState('photo')
  useEffect(()=>{
    if(projectData?.floor_plan?.length){
      setSelectedOption(projectData?.floor_plan[0]?.id)
      setSelectedImageFloor(projectData?.floor_plan[0]?.attachments)
    }
    if(projectData?.payment_plan?.length){
      setSelectedOption1(projectData?.payment_plan[0]?.id)
      setSelectedImagePlan(projectData?.payment_plan[0]?.attachments)
    }
  },[projectData])


  const [idx,setIdx]=useState(0)
  return (
    <>
      <div className="container-fluid px-4 pt-4 housing-society">
        <div className="row mt-1 mx-0 p-0 ">
          <div className="col-lg-8 col-12 p-0">
            <div className="front-img">

              <div className="top-icon-box">
                <div className="first-box d-flex">
                  <span className="camera"><FaCamera style={{ marginRight: "4px" }} /> 15</span>
                  <span className="video"><FaVideo style={{ marginRight: "4px" }} /> 1</span>
                </div>
                <div className="second-box">
                  <FaCheck style={{ marginRight: "5px" }} /> Verified
                </div>
              </div>

              <div className="bottom-icon-box">
                <div className='markit-project'>
                  <div className='p-markit'>Marketed By</div>
                  <div className='project-icon d-flex justify-content-center py-1'>
                    <img className="h-100" src={icon14} alt='...' />
                  </div>
                </div>
              </div>
              <FallbackImage style={{objectFit:"cover"}} src={projectData?.images?.length?projectData?.images[0].url:front_img} alt={"..."} fallbackSrc={front_img} className={"w-100 h-100"}/>
              {/* <img className="w-100 h-100" src={projectData?.images?.length?projectData?.images[0].url:front_img} alt="..." /> */}
            </div>
            <div className="details-card-box mt-3 px-3 py-4">
              <div className="location-box mx-1">
                <div className="row m-0 p-0">
                  <div className="col-md-10 col-sm-5 col-12 p-0 order-sm-1 order-2">
                    <div className="location-details-box d-flex justify-content-sm-start justify-content-center">
                      <div className="img-box my-1">
                        <img src={img} alt="" />
                      </div>
                      <div className="location mx-3 d-md-inline d-none">
                        <div className="society-name ">{projectData?.project_title}</div>
                        <div className="society-location my-1 "><IoLocationOutline /> {projectData?.location?.name}, {projectData?.city?.city} </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-2 col-sm-7 col-12 mb-sm-0 mb-4 p-0 order-sm-2 order-1 ">
                    <div className="location-sharing-icons d-flex justify-content-end">
                      <div className="icon"><FaRegHeart /></div>
                      <div className="icon mx-2"><PiShareFatBold /></div>
                      <div className="icon"><FiPrinter /></div>
                    </div>
                  </div> */}
                  <div className="col-12 p-0 order-3">
                    <div className="location mx-3 d-md-none d-inline">
                      <div className="society-name text-sm-start text-center">{projectData?.project_title}</div>
                      <div className="society-location my-1 text-sm-start text-center"><IoLocationOutline /> {projectData?.location?.name}, {projectData?.city?.city}  </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 my-2 order-4">
                    <div className="house-price d-sm-inline d-none">PKR {formatPrice(projectData?.min_price)} to PKR {formatPrice(projectData?.max_price)}</div>
                    <div className="house-price d-sm-none d-inline">PKR {formatPrice(projectData?.min_price)} to PKR {formatPrice(projectData?.max_price)}</div>
                  </div>
                </div>
              </div>
              <div className="developer-details mx-1 p-2">
                <div className="row m-0 p-0">
                  <div className="col-md-6 col-12 p-0">
                    <div className="developer">
                      <div className="heading">Developer</div>
                      <div className="company-name my-1">{projectData?.developer_name}</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 p-0">
                    <div className="offers">
                      <div className="heading">Offering</div>
                      <div className="offered-items d-flex justify-content-sm-start justify-content-center mt-2 mb-1" style={{ flexWrap: "wrap" }}>
                     {projectData?.project_categories?.map((item)=>{
                        return(
                          <div className="items mb-2">
                          <img src={img1} alt="..." />
                          {item?.name}
                        </div>
                        )
                     })  }
                        {/* <div className="items mb-2">
                          <img src={img2} alt="..." />
                          Home
                        </div>
                        <div className="items mb-2">
                          <img src={img3} alt="..." />
                          Apartment
                        </div>
                        <div className="items">
                          <img src={img4} alt="..." />
                          Office / Shops
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="slot house-slot mx-1  my-4">
                <div className="d-flex justify-content-between w-100 row m-0">
                  <div className="col-sm-4 col-12 p-0">
                    <div className="heading">
                      <img src={img5} alt="" />
                      Plot
                    </div></div>
                  <div className="col-sm-8 col-12 p-0  d-flex justify-content-end">
                    <div className="price d-sm-flex d-none">
                      <span> PKR</span> 2.21 Crore to 2.74 Crore  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                    <div className="price  d-sm-none d-flex">
                      <span> PKR</span> 2.21 Cr to 2.74 Cr  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                  </div>
                </div>
              </div>


              <div className="slot house-slot mx-1 my-4">
                <div className="d-flex justify-content-between w-100 row m-0">
                  <div className="col-sm-4 col-12 p-0">
                    <div className="heading">
                      <img src={img16} alt="House" />
                      House
                    </div>
                  </div>

                  <div className="col-sm-8 col-12 p-0 d-flex justify-content-end">
                  <div className="price d-sm-flex d-none">
                      <span> PKR</span> 2.21 Crore to 2.74 Crore  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                    <div className="price  d-sm-none d-flex">
                      <span> PKR</span> 2.21 Cr to 2.74 Cr  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                  </div>
                </div>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="modal-box">
                      <div className="row mx-0 px-3 pt-3 mb-1">
                        <div className="col-8 p-0 d-flex align-items-center"> <div className="modal-heading">1 bed apartments of 1st to 6th floor</div></div>
                        <div className="col-4 p-0 d-flex justify-content-end align-items-center"><div className="close-btn" onClick={handleClose}><IoClose /></div></div>
                      </div>
                      <hr className="mt-2 mb-1" />
                      <div className="img-box">
                        <img className="w-100 h-100" src={img10} alt="" />
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>

              <div className="slot house-slot mx-1  my-4">
                <div className="d-flex justify-content-between w-100 row m-0">
                  <div className="col-sm-4 col-12 p-0"><div className="heading">
                    <img src={img15} alt="" />
                    Apartments
                  </div></div>
                  <div className="col-sm-8 col-12 p-0 d-flex justify-content-end">
                  <div className="price d-sm-flex d-none">
                      <span> PKR</span> 2.21 Crore to 2.74 Crore  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                    <div className="price  d-sm-none d-flex">
                      <span> PKR</span> 2.21 Cr to 2.74 Cr  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                  </div>

                </div>
              </div>

              <div className="slot house-slot mx-1  my-4">
                <div className="d-flex justify-content-between w-100 row m-0">
                  <div className="col-sm-4 col-12 p-0"><div className="heading">
                    <img src={img13} alt="" />
                    Offices
                  </div></div>
                  <div className="col-sm-8 col-12 p0 d-flex justify-content-end">
                  <div className="price d-sm-flex d-none">
                      <span> PKR</span> 2.21 Crore to 2.74 Crore  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                    <div className="price  d-sm-none d-flex">
                      <span> PKR</span> 2.21 Cr to 2.74 Cr  {
                        button === false ?

                          <IoMdArrowDropup onClick={() => setButton(true)} style={{ marginLeft: "8px" }} />
                          :
                          <IoMdArrowDropdown onClick={() => setButton(false)} style={{ marginLeft: "8px" }} />

                      }
                    </div>
                  </div>

                </div>
              </div> */}
                {projectData?.project_categories?.map((item,i)=>{   
                  return(
                    <div className="slot house-slot mx-1  my-4">
                    <div className="d-flex justify-content-between w-100 row m-0">
                      <div className="col-sm-4 col-12 p-0 ">
                        <div className="heading">
                          <img src={img14} alt="" />
                          {item?.name}
                        </div>
                      </div>
                      <div className="col-sm-8 col-12 p-0 d-flex justify-content-end">
                      <div className="price d-sm-flex d-none">
                          <span> PKR</span> {formatPrice(item?.min_price)} to {formatPrice(item?.max_price)}  {
                            idx ===i  ?
    
                              <IoMdArrowDropup onClick={() => setIdx(i)} style={{ marginLeft: "8px" }} />
                              :
                              <IoMdArrowDropdown onClick={() => setIdx("")} style={{ marginLeft: "8px" }} />
    
                          }
                        </div>
                        <div className="price  d-sm-none d-flex">
                          <span> PKR</span> {formatPrice(item?.min_price)} to {formatPrice(item?.max_price)}  {
                           idx === i ?
    
                              <IoMdArrowDropup onClick={() => setIdx(i)} style={{ marginLeft: "8px" }} />
                              :
                              <IoMdArrowDropdown onClick={() => setIdx("")} style={{ marginLeft: "8px" }} />
    
                          }
                        </div>
                      </div>
    
                    </div>
                    <div className={idx ===i ? 'floor-box w-100' : 'w-100'} >
                      <div>
                        {item?.project_properties?.map((item, index) => (
                          <div className="Ground-floor w-100 p-3 my-1" key={index}>
                            <div className="floor-item">
                              <div className="d-flex w-100 justify-content-between row m-0">
                                <div className="col-sm-6 col-12 p-0"><div className="heading">{item.property_title}</div></div>
                                <div className="col-sm-6 col-12 p-0 d-flex justify-content-sm-end justify-content-start">
                                  <div className="price d-sm-inline d-none">
                                  <span>PKR</span> {formatPrice(item.minimum_price_range)}
                                </div>
                                <div className="price  d-sm-inline d-none"> 
                               to   <span>PKR</span> {formatPrice(item.maximum_price_range)}
                                </div>
                                </div>
                              </div>
    
                              <div className="hr-line w-100 my-sm-3 mt-2 mb-3"></div>
    
                              <div className="scroll-box">
                                <div className="floor-details row mx-0 mt-4 p-0">
                                <div className="col-3 p-0" >
                                      <div className="details-small-card">
                                        <div className="row mx-0 mb-2">
                                          <div className="col-3 p-0">
                                            <div className="img-box h-100">
                                              <img src={img6} alt="kjasj" />
                                            </div>
                                          </div>
    
                                          <div className="col-9 px-3">
                                            <div className="details px-2">
                                              <div className="heading-box">Area</div>
                                              <div className="number my-2">{item?.maximum_area_range} {item?.area_unit}</div>
                                              {/* {detail.link && (
                                                <div className="link-box my-2">
                                                  <div onClick={handleOpen}>
                                                    {detail.linkText} <MdArrowOutward />
                                                  </div>
                                                </div>
                                              )} */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  )
                })}
            

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="modal-box">
                    <div className="row mx-0 px-3 pt-3 mb-1">
                      <div className="col-8 p-0 d-flex align-items-center">
                        <div className="modal-heading">1 bed apartments of 1st to 6th floor</div>
                      </div>
                      <div className="col-4 p-0 d-flex justify-content-end align-items-center">
                        <div className="close-btn" onClick={handleClose}>
                          <IoClose />
                        </div>
                      </div>
                    </div>
                    <hr className="mt-2 mb-1" />
                    <div className="img-box">
                      <img className="w-100 h-100" src={img10} alt="" />
                    </div>
                  </div>
                </Box>
              </Modal>
              <div className="slot-text px-1">
{projectData?.developer_description}
              </div>
              <div className="read-button d-flex justify-content-end my-3">
                <div className="slot-read-function px-4">
                  Read more <IoMdArrowDropdown />
                </div>
              </div>
              <div className='hr-line' />

             {projectData?.project_amenitie?.length&& <div className="aminty my-3">
                <h1 className='mb-2 floor-heading'>Amenities</h1>
                <div className="mian_feature shadowed-main-feature px-4 py-3"> 
                  {projectData?.project_amenities?.map((item)=>{
                    return(
                      <>
                      <h3 className="text-sm-start text-center">{item?.category_name}</h3>
                      
                      <div className="row">
                      {item?.data?.map((items)=>{
                        return(
                          <div className="col-lg-4 col-sm-6 col-12 text-sm-start text-center aminities-items my-1">
                          <span><img src={icon} alt="" />{items?.label} {items?.type==="text" || items?.type==="select"?item?.selected_options:""}</span>
                        </div>
                        )
                      })}

  
                    </div>
                      </>


                    )
                  })}
                
                </div>
              </div>}

              <h1 className='mt-4 pt-3 mb-2 floor-heading'>Location & Nearby</h1>
              <iframe title="map" src={`https://maps.google.com/maps?q=${projectData?.location?.geo_location?.lat},${projectData?.location?.geo_location?.lng}&z=15&output=embed`} width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

              <div className="floor-plan my-4">
                <div className="floor-heading my-2">Floor Plans</div>
                <div className="plan-box">
                  <div className="row m-0 p-4">
                    <div className="col-md-4 col-12 p-0">
                      <div className="options-box scroll-box mb-md-0 mb-3  d-md-block d-flex">
                        {projectData?.floor_plan?.map((option) => (
                          <div
                            key={option.id}
                            className={selectedOption === option.id ? "option" : "option1"} // Conditionally apply classes
                            onClick={() => handleClick(option.id,option?.attachments)} // Handle the click event
                          >
                            <img
                              src={selectedOption === option.id ? icon12 : icon13}
                              alt={option.label}
                            />
                            {option.name}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-8 col-12 p-0 ">
                      <div className="floor-img-box">
                        <img className='w-100' src={selectFloorImg?selectFloorImg:front_img} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="development-upadtes my-4">
                <div className="development-heading my-2">Development Updates</div>
                <div className="development-plan-box pt-4 pb-3 px-3">
                  <div className="row m-0 p-0">
                    {/* Left Arrow */}
                    <div className="col-1 p-0">
                      <div className="arrow d-flex align-items-center" onClick={handleLeftArrowClick}>
                        <GoArrowLeft />
                      </div>
                    </div>

                    {/* Month Navigation */}
                    <div className="col-10 px-4 d-flex justify-content-between align-items-center" style={{ overflow: 'hidden' }}>
                      {/* Display the month-box with the current month on the left */}
                      <div className="month-box">
                        <div className="month">
                          {currentMonth}
                        </div>
                      </div>

                      <div className="line"></div>

                      {/* Dynamically render the remaining 5 months */}
                      {currentMonths.slice(1).map((month, index) => (
                        <React.Fragment key={index}>
                          <div className="month-circle col-12 col-sm-4 col-md-3">
                            {month}
                          </div>
                          {/* Render line between months, except after the last one */}
                          {index < currentMonths.length - 2 && <div className="line"></div>}
                        </React.Fragment>
                      ))}
                    </div>

                    {/* Right Arrow */}
                    <div className="col-1 p-0">
                      <div className="arrow d-flex justify-content-end align-items-center" onClick={handleRightArrowClick}>
                        <GoArrowRight />
                      </div>
                    </div>
                  </div>


                  <div className="scroll-box d-flex" style={{ gap: '20px' }}>
                    <div className="development-card d-flex align-items-center just">
                      <div className="development-img-box" onClick={handleNewOpen}>
                        <div className="pic-box d-flex align-items-center justify-content-between px-1">
                          <div className="camera-box d-flex align-items-center"><FaCamera style={{ marginRight: "3px" }} /> 15</div>
                          <div className="line"></div>
                          <div className="video-box d-flex align-items-center"><IoVideocamSharp style={{ marginRight: "3px" }} /> 1</div>
                        </div>
                        <img src={img12} alt="" />
                      </div>
                      <div className="development-card-details px-3 py-2">
                        <div className="heading mb-2">Construction Updates</div>
                        <div className="posted-date">
                          <span><FiClock /></span>Posted: May 30, 2024
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    open={newOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style1}>
                      <div className="modal-box">
                        <div className="row mx-0 px-3 pt-3 mb-1">
                          <div className="col-11 p-0 d-flex align-items-center">
                            <div className="modal-heading">Construction Update (May 2024)</div>
                          </div>
                          <div className="col-1 p-0 d-flex justify-content-end align-items-center">
                            <div className="close-btn" onClick={handleNewClose}>
                              <IoClose />
                            </div>
                          </div>
                        </div>
                        <hr className="mt-2 mb-1" />
                        <div className="modal-data">
                          <div className="row m-0 w-100 align-items-end justify-content-center">
                            <div className="col-3 p-0">
                              <div className={ tab === 'photo'? "active" : "tab"} onClick={() => setTab('photo')}>Photo({imageArray.length})</div>
                            </div>
                            <div className="col-3 p-0">
                              <div className={ tab === 'video'? "active" : "tab"}  onClick={() => setTab('video')} >Video(1)</div>
                            </div>
                            <div className="col-6 p-0">
                              <div className="tab"></div>
                            </div>
                            <div className="col-11 d-flex justify-content-center p-0">
                              <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: 'column' }}>
                                <div className="new_img-box">
                                  <img className="w-100 h-100" src={image} alt="" />
                                </div>
                                <div className="row m-0 justify-content-center">
                                  <div className="col-9 px-0">
                                    <div className="scroll-box">
                                      <div className="small-img-box d-flex">
                                        {imageArray.map((item, index) => (
                                          <div
                                            key={index}
                                            className="box"
                                            onClick={() => setImage(item.img)}
                                          >
                                            <img src={item.img} alt={`image-${index}`} />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>

              <div className="floor-plan payment-plan my-4">
                <div className="floor-heading my-2">Payment Plan</div>
                <div className="plan-box">
                  <div className="row m-0 p-4">
                    <div className="col-md-4 col-12 p-0">
                      <div className="options-box scroll-box d-md-block d-flex mb-md-0 mb-3">
                        {projectData?.payment_plan?.map((option) => (
                          <div
                            key={option.id}
                            className={selectedOption1 === option.id ? "option" : "option1"} // Conditionally apply classes
                            onClick={() => handleClick1(option.id,option?.attachments)} // Handle the click event
                          >
                            <img
                              src={selectedOption1 === option.id ? icon12 : icon13} // Set icon based on selection
                              alt={option.label}
                            />
                            {option.name}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-8 col-12 p-0 ">
                      <div className="floor-img-box">
                        <img className='w-100' src={selectPlanImg?selectPlanImg:front_img} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div >
          <div className="col-lg-4 col-12 ps-lg-4 ps-0 pe-0 mt-lg-0 mt-5">
            <div className="logo_prop p-3">
              <div className="logo_info">
                <div className="logo_img">

                  <img src={img_2} alt="" />
                </div>

                <p className="heading_info">{projectData?.developer_name}</p>
                <p className="content_info">
                  {projectData?.email}
                 
                </p>
                <div className="info_btns">
                  <button onClick={() => {
                                handleOpen1()

                            }}><IoIosCall className="me-2"/>Call</button>
                              <a  href={`https://wa.me/${projectData?.contacts?.length?projectData?.contacts[0]:projectData?.land_line}`} target="_blank" rel="noopener noreferrer">
                  <button><img src={whatsapp} alt="" className="me-2" />WhatsApp</button>
                  </a>
                </div>

              </div>
              <form onSubmit={submit}>
                        <div className='inp_login mb-3 p'>
                            <label className="logo_label">Name</label>
                            <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Name' />
                            {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}        </div>
                        <div className='inp_login mt-2'>
                            <label className="logo_label mt-2">Email</label>
                            <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>

                        <div className='inp_login'>
                            <label className="logo_label">Contact</label>
                            <div className='contact_contry'>
                                <div className='img_container'>
                                    <img src={flag} alt='...' />
                                    <span>+92</span>
                                </div>
                                <input style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} onChange={(e) => setData({ ...data, phone_number: formatPakistaniPhoneNumber(e.target.value) })} type='number' placeholder='e.g 3324751060' />
                            </div>
                            {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                        </div>
                        <div className='inp_login mb-0'>
                            <label className="logo_label">Message</label>
                            <textarea style={{ minHeight: "100px", width: "100%", border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem" }} onChange={(e) => setData({ ...data, message: e.target.value })} type='email' placeholder='Write your message.....' />
                            {fieldErrors?.message && <span className='text_error' style={{top:'97%'}}>{fieldErrors?.message}</span>}
                        </div>
                        <div className='form_radio'>
                            <label className="logo_label">I am a:</label>
                            <Radio
                                {...controlProps('buyer/tenant')}
                                checked={data?.type === "buyer/tenant" ? true : false}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Buyer / Tenant</span>
                            <Radio
                                {...controlProps('agent')}
                                checked={data?.type === "agent" ? true : false}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Agent</span>
                            <Radio
                                {...controlProps('other')}
                                checked={data?.type === "other" ? true : false}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Other</span>
                            {fieldErrors?.type && <span className='text_error'>{fieldErrors?.type}</span>}
                            <div className="inp_login">
                                <input style={{ borderRadius: "5px", color: 'black' }} type='text' value='Invester' readOnly />
                            </div>
                        </div>
                        {/* <div className='inp_login px-2'>
                            <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Investor' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div> */}
                        <div className='form_radio d-flex align-items-center'>
                            <Checkbox
                                {...label}
                                style={{ marginTop: ".5rem", borderRadius: '6px' }}
                                onChange={(e) => setData({ ...data, keep_informed: e.target.checked ? true : false })}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <label className="logo_label">Keep me informed about similar properties.</label>
                            {/* {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>} */}
                        </div>
                        <hr className="new_hr_line mt-3 mb-0" />
                        <button type='submit' className='Sign_inBtn py-3'>Send Email</button>
                    </form>
            </div>
            <Call profileData={{user:{
              avatar:projectData?.images[0].url,
              phone_number:projectData?.land_line,
              landline:projectData?.land_line,
              email:projectData?.email
            }}} open={open1} setOpen={setOpen1} />
          </div>
        </div >
      </div >
    </>
  )
}

export default Banner
