import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HousingSocietyDetailsExp/Banner'
import SimilarsProjects from '../Camponent/HousingSocietyDetailsExp/SimilarsProjects'
import PopularSearches from '../Camponent/HousingSocietyDetailsExp/PopularSearches'
import Footer from '../Camponent/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'
import { useParams } from 'react-router-dom'
function HousingSocietyDetailsExp() {
  const {projectDetail}=useAuth()
  const parms=useParams()
  const [projectData,setProjectData]=useState()
  useEffect(()=>{
    const fetchData = async () => {
      try {
        let result = await projectDetail(parms?.id);
        if (result?.success) {
          const propertyTypes = result?.data?.data?.project;
          setProjectData(propertyTypes)
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    }
    fetchData()
  },[])
  return (
    <>
      <NavBar />
      <Banner projectData={projectData}/>
      <SimilarsProjects />
      <PopularSearches />
      <Footer />
    </>
  )
}
export default HousingSocietyDetailsExp