import React, {  useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useAuth } from '../../Context/ContextProvider';
import logo from '../../Images/logo.png'
import google from '../../Images/icons/google.png'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "5px"
};
function LoginModal({ open, handleClose ,handleOpen,setFieldErrors,fieldErrors}) {
  const [password, setPassword] = useState(false)
 
  const { login,loading } = useAuth();
  const navigate = useNavigate(); 

  const [data, setData] = useState({
    email: "",
    password: ""
  })
  const validateStep = () => {
    let errors = {

    };
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.password) {
      errors.password = "Password is required.";
    }
    setFieldErrors(errors);
    
    return Object.keys(errors).length === 0;
  };
  const submit = async (event) => {
    event.preventDefault();
  if (validateStep()) {
    const result = await login(data);
    if (result?.success) {
      setData({
        email: "",
        password: ""
      })
      navigate('/agent/dashboard'); 
    } else {
      if(result?.error?.errors?.email){
        setFieldErrors({
          email:result?.error?.errors?.email[0]
        })
      }
      if(result?.error?.message){
        setFieldErrors({
          message:result?.error?.message
        })
      }

      console.log('Login failed:', result);
    }
  }
}

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='login_modal px-4'>
            <div className='d-flex justify-content-center p-3'>
              <img src={logo} alt='...' />

            </div>
            <div className='d-flex justify-content-between mt-2'>
              <div style={{position:"relative"}}>
              <p>Please Sign In</p>
              {fieldErrors?.message && <span style={{top:"70%",whiteSpace:"nowrap"}} className='text_error'>{fieldErrors?.message}</span>}
              </div>
              <div onClick={()=>{
                handleOpen()
                handleClose()
              }}>
              <span>Don't have an account? <Link className='link'>Sign Up</Link></span>

              </div>
            </div>
            <form onSubmit={submit}>
            <div className='inp_login'>
              <label>Email</label>
              <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
              {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
           
            </div>
            <div className='inp_login'>
              <label>Password</label>
              <div style={{ position: "relative" }}>
                <input type={`${password ? "text" : "password"}`} onChange={(e) => setData({ ...data, password: e.target.value })} placeholder='Password' />
                {!password ? <FaRegEyeSlash onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                  <FaRegEye onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} />}
              </div>
              {fieldErrors?.password && <span className='text_error'>{fieldErrors?.password}</span>}
            </div>

            <button type='submit' className='Sign_inBtn'>{loading?<div className="spinner-border text-light button_loading" role="status">

</div>:"Sign In"}</button>
            </form>
            <div className='d-flex justify-content-center'>
              <span className='text-center'>Or sign in with</span>
            </div>
            <div className='d-flex justify-content-center my-4'>
              <button className='socail_login'><img src={google} alt='google' />Continue with Google</button>

            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default LoginModal
