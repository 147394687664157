import React, { useState } from 'react';
import img from '../../Images/About/Property 1=Frame 1300193072.svg';
import img1 from '../../Images/About/Property 1=Frame 1300193073.svg';

import {aboutData} from '../../AgentDashBorad/Component/dummyData'
import { FallbackImage } from '../FallbackImage';
function Entrance() {
    const [active,setActive]=useState("High Rise")
    const [activeData,setActiveData]=useState(aboutData[0])
    console.log(activeData);
    
    return (
        <>
            <div className="container-fluid mx-0 entrance-container p-0">
                <div className="row my-4 mx-0 p-0">
                    <div className="main-img d-flex">
                        <div className="animated-img d-flex">
                        <img className="w-100 h-100" src={img} alt="..." />
                        <img style={{marginLeft:'-30px'}} className="w-100 h-100" src={img1} alt="..." />
                        </div>
                    </div>
                </div>
                <div className="row mt-4 pt-1 px-0 mx-0">
                    <div className="col-xl-2 col-md-3 col-12 m-0 p-0">
                    <div className="item-box my-4 mx-0">
                            <div className="first-item my-2"></div>
                            {aboutData?.map((item)=>{
                                return(
                                    <>
                                    {active===item?.name?<div style={{cursor:"pointer"}} onClick={()=>{
                                        setActive(item?.name)
                                        setActiveData(item)
                                        }} className="second-item px-2 my-3">
                                <span className="item-line"></span> {item?.name}
                            </div>:<div style={{cursor:"pointer"}} onClick={()=>{
                                setActive(item?.name)
                                setActiveData(item)
                                }} className="first-item my-2">{item?.name}</div>}
                                    </>
                                )
                            })}
                            {/* <div className="first-item my-2">High Rise</div>
                            <div className="first-item my-2">High Rise</div> */}
                        
                            {/* <div className="first-item item-third">Lease</div>
                            <div className="first-item my-3">Instant Valuation</div>
                            <div className="first-item ">Post Property</div> */}
                        </div>
                    </div>

                    <div className="col-xl-5 col-md-9  col-12 details-col">
                        <div className="main-parabox">
                            <h5>{activeData?.heading}</h5>
                            <p className="para mt-3">
                            {activeData?.pera1}
                            </p>
                            <p className="para mt-3">
                            {activeData?.pera2}
                            </p>
                            <p className="para mt-3">
                            {activeData?.pera3}
                            </p>
                            <p className="para mt-3">
                            {activeData?.pera4}
                            </p>

                           
                        </div>
                    </div>

                    <div className="col-xl-5 col-12 colorbox-col" style={{ paddingLeft: '75px', paddingRight: "22px" }}>
                        <div className="colored-box">
                            <FallbackImage style={{width:"100%",height:"100%",objectFit:"cover"}} src={activeData?.img}/>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Entrance;
