import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useAuth } from '../../Context/ContextProvider';
import Checkbox from '@mui/material/Checkbox';
import { teal } from '@mui/material/colors';
import logo from '../../Images/logo.png'
import { useMediaQuery } from '@mui/material';
import flag from '../../Images/listing/pak.png'
import google from '../../Images/icons/google.png'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function SignUpModal({ open, handleClose, handleOpen }) {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [password, setPassword] = useState(false)
    const [fieldErrors, setFieldErrors] = React.useState({})
    const { registar, loading } = useAuth()

    const [data, setData] = useState({
        email: "",
        password: "",
        password_confirmation: "",
        name: "",
        contact: "",
        guard_name: "api",
        role: "agent",
        terms_and_conditions: false
    })
    const validateStep = () => {
        let errors = {

        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!data.password) {
            errors.password = "Password is required.";
        } else if (data.password.length < 8 || data.password.length > 10) {
            errors.password = "Password must be between 8 and 10 characters long.";
        }
        else if (!hasSpecialCharacter(data.password)) {
            errors.password = "Password must contain at least one special character.";
        }
        if (!data.password_confirmation) {
            errors.password_confirmation = "Confirm Password is required.";
        } else if (data.password !== data.password_confirmation) {
            errors.password_confirmation = "Passwords do not match.";
        }
        if (!data.terms_and_conditions) {
            errors.terms_and_conditions = "Please accept the terms and conditions.";
        }
        if (!data.contact) {
            errors.contact = "Contact is required.";
        }
        else if (!isValidPakistaniPhoneNumber(data.contact)) {
            errors.contact = "Please enter a valid Pakistan phone number.";
        }
        setFieldErrors(errors);
        
        return Object.keys(errors).length === 0;
    };
    const submit = async (event) => {
        event.preventDefault();
    
        if (validateStep()) {
            setFieldErrors({})
            try {
                const result = await registar({
                    name: data?.name,
                    password: data?.password,
                    email: data?.email,
                    phone_number: data?.contact,
                    password_confirmation: data?.password_confirmation,
                    guard_name: "api",
                    role: data?.role,
                    terms_and_conditions: data?.terms_and_conditions
                });

              
    
                if (result?.success) {
                    setData({
                        email: "",
                        password: "",
                        password_confirmation: "",
                        name: "",
                        contact: "",
                        guard_name: "api",
                        role: "agent",
                        terms_and_conditions: false
                    })
                    handleClose();
                } else {
                   
                    
                    const error = result?.error?.errors;
                    
                    if (error?.email && error?.phone_number) {
                        setFieldErrors({
                            email: error?.email[0],
                            contact: error?.phone_number[0]
                        });
                    } else if (error?.email) {
                        setFieldErrors({
                            email: error?.email[0],
                        });
                    } else if (error?.phone_number) {
                        setFieldErrors({
                            contact: error?.phone_number[0],
                        });
                    } else {
                      
                        console.log("No specific field errors found. Full error message:", result?.error?.message || result);
                    }
                }
            } catch (e) {
                
                console.error("Unexpected error during registration:", e);
            }
        }
    };
    
    
    
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const hasSpecialCharacter = (password) => {
       
        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
        return specialCharacterRegex.test(password);
    };
    const formatPakistaniPhoneNumber = (phoneNumber) => {

        let phone = phoneNumber.replace(/\D/g, ''); 

      
        if (phone.startsWith('92')) {
            phone = phone.slice(2);
        } else if (phone.startsWith('0')) {
            phone = phone.slice(1);
        }

        if (phone.length === 10) {
            return `+92${phone}`;
        }

        return phoneNumber; 
    };
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

        return isValid;
    };




    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isSmallScreen ? '90%' : '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: "5px"
                }}>
                    <div className='login_modal px-4'>

                        <div className='d-flex justify-content-center p-3'>
                            <img src={logo} alt='...' />

                        </div>


                        <div className='d-flex justify-content-between align-items-center mt-2'>
                            <p className='m-0'>Please Sign Up</p>
                            <div onClick={() => {
                                handleOpen()
                                handleClose()
                            }}>

                                <span>Already have an account? <Link className='link'>Sign in</Link></span>
                            </div>
                        </div>
                        <form onSubmit={submit}>
                            <div className='registar_form row'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='inp_login'>
                                        <label>Name</label>
                                        <input value={data?.name} onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Name' />
                                        {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}        </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>

                                    <div className='inp_login'>
                                        <label>Email</label>
                                        <input value={data?.email} onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                        {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='inp_login'>
                                        <label>Password</label>
                                        <div value={data?.password} style={{ position: "relative" }}>
                                            <input type={`${password ? "text" : "password"}`} onChange={(e) => setData({ ...data, password: e.target.value })} placeholder='Password' />
                                            {!password ? <FaRegEyeSlash onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                                <FaRegEye onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                                        </div>
                                        {fieldErrors?.password && <span className='text_error'>{fieldErrors?.password}</span>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>

                                    <div className='inp_login'>
                                        <label>Confirm Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input value={data?.password_confirmation} type={`${password ? "text" : "password"}`} onChange={(e) => setData({ ...data, password_confirmation: e.target.value })} placeholder='Confirm Password' />
                                            {!password ? <FaRegEyeSlash onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                                <FaRegEye onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                                        </div>
                                        {fieldErrors?.password_confirmation && <span className='text_error'>{fieldErrors?.password_confirmation}</span>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='inp_login'>
                                        <label>Contact</label>
                                        <div className='contact_contry'>
                                            <div className='img_container'>
                                                <img src={flag} alt='...' />
                                                <span>+92</span>
                                            </div>
                                            <input value={data?.contact} style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} onChange={(e) => setData({ ...data, contact: formatPakistaniPhoneNumber(e.target.value) })} type='text' placeholder='e.g 3324751060' />
                                        </div>
                                        {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 d-flex align-items-center'>
                                    <div className='inp_login'>
                                        <Checkbox
                                            {...label}
                                            checked={data.role==="agency"?true:false}
                                            onChange={(e) => setData({ ...data, role: e.target.checked ? "agency" : "agent" })}
                                            sx={{
                                                color: teal[800],
                                                padding: "0",
                                                margin: "0",
                                                '&.Mui-checked': {
                                                    color: teal[600],
                                                },
                                            }}
                                        />
                                        <label className='mx-2'>Register as an Agency</label>

                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='inp_login mt-0'>
                                        <Checkbox
                                            {...label}
                                            checked={data?.terms_and_conditions}
                                            onChange={(e) => setData({ ...data, terms_and_conditions: e.target.checked })}
                                            sx={{
                                                color: teal[800],
                                                padding: "0",
                                                margin: "0",
                                                '&.Mui-checked': {
                                                    color: teal[600],
                                                },
                                            }}
                                        />
                                        <label className='mx-2'>I have read and I agree to the <Link style={{ textDecoration: "none" }}>Terms & Conditions</Link> of Pakistan Property</label>
                                        {fieldErrors?.terms_and_conditions && <span className='text_error'>{fieldErrors?.terms_and_conditions}</span>}
                                    </div>

                                </div>

                            </div>
                            <button type='submit' className='Sign_inBtn'>{loading ? <div className="spinner-border text-light button_loading" role="status">

                            </div> : "Sign Up"}</button>
                        </form>
                        <div className='d-flex justify-content-center'>
                            <span className='text-center'>Or sign Up with</span>
                        </div>
                        <div className='d-flex justify-content-center my-4'>
                            <button className='socail_login'><img src={google} alt='google' />Continue with Google</button>
                           

                        </div>
                    </div>
                </Box>
            </Modal>
        </div>


    )
}

export default SignUpModal
