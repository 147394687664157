import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { RxMagnifyingGlass } from 'react-icons/rx';

export default function Banner() {
    const [openIndex, setOpenIndex] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const faqData = [
        {
            question: "1. What types of properties can I find on Pakistan Property?",
            answer: "You can browse residential, commercial, and rental properties across various cities in Pakistan.",
        },
        {
            question: "2. How do I search for properties in a specific city or area?",
            answer: "Because TanahAir provides the best service to customers and provides flexibility to solve problems with our experts so that customers get satisfaction. And we provide service very quickly according to the price we offer.",
        },
        {
            question: "3. Can I list my property for sale or rent on this website?",
            answer: "TanahAir conducts thorough research and collaborates with clients to ensure the content aligns with their business objectives.",
        },
        {
            question: "4. Are the property listings verified?",
            answer: "Results are reported on a weekly or monthly basis, depending on the client's preference.",
        },
        {
            question: "5. How can I contact the property owner or agent?",
            answer: "Deliverables include design drafts, development updates, and final products, delivered as per the agreed timeline.",
        },
        {
            question: "6. How do I schedule a visit to a property I'm interested in?",
            answer: "You can contact the property owner or agent directly through the contact details provided in the listing to arrange a visit.",
        },
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredFAQs = faqData.filter((faq) => {
        return (
            faq.question.toLowerCase().includes(searchQuery) ||
            faq.answer.toLowerCase().includes(searchQuery)
        );
    });

    return (
        <>
            <div className="container-fluid p-4">
                <div style={{ display: 'flex', justifyContent: 'center' }} className="row m-0 mian-faqa">
                    <div className="col-lg-9 p-0">
                        <div className="row m-0">
                            <div className="col-lg-12 col-md-3 col-12 m-0 p-0">
                                <div className="item-box my-4 mx-0">
                                    <div className="faqs-heading">
                                        <h2>Frequently Asked Questions</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 p-0">
                                <div className="faq-mian-box">
                                    <div className="faq-input">
                                        <input
                                            type="text"
                                            placeholder="Write your question here"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                        <span>
                                            <RxMagnifyingGlass />
                                        </span>
                                    </div>
                                    <div className="fa-mian-services">
                                        {filteredFAQs.length > 0 ? (
                                            filteredFAQs.map((faq, index) => (
                                                <div
                                                    key={index}
                                                    className={`faq-services my-3 ${openIndex === index ? 'active' : ''}`}
                                                >
                                                    <span
                                                        className="d-flex justify-content-between"
                                                        onClick={() => toggleFAQ(index)}
                                                    >
                                                        <h3>{faq.question}</h3>
                                                        <span style={{ color: '#1C9093' }}>
                                                            {openIndex === index ? <AiOutlineMinus /> : <AiOutlinePlus />}
                                                        </span>
                                                    </span>
                                                    {openIndex === index && (
                                                        <p style={{ marginTop: '10px' }}>{faq.answer}</p>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <p>No matching words found.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
