import React, { useState } from 'react'
import { FaBriefcase, FaPhone } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import {IoLogoWhatsapp} from 'react-icons/io'
import {IoMailSharp } from 'react-icons/io5';
import { useAuth } from '../../Context/ContextProvider';

function Banner() {
    const {contact_us}=useAuth()
    const [data, setData] = useState({
        email: "",
        subject: "",
        description:"",
        name: "",
        phone_number: "",
    })
    const [fieldErrors, setFieldErrors] = React.useState({})

    const validateStep = () => {
        let errors = {

        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.subject) {
            errors.subject = "Subject is required.";
        }
        if (!data.description) {
            errors.description = "Description is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!data.phone_number) {
            errors.contact = "Contact is required.";
        }
        else if (!isValidPakistaniPhoneNumber(data.phone_number)) {
            errors.contact = "Please enter a valid Pakistan phone number.";
        }
        setFieldErrors(errors);
       
        return Object.keys(errors).length === 0;
    };
    const formatPakistaniPhoneNumber = (phoneNumber) => {

        let phone = phoneNumber.replace(/\D/g, '');

        
        if (phone.startsWith('92')) {
            phone = phone.slice(2);
        } else if (phone.startsWith('0')) {
            phone = phone.slice(1);
        }

        if (phone.length === 10) {
            return `+92${phone}`;
        }

        return phoneNumber; 
    };
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

        
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
        
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

        return isValid;
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const submit = async (event) => {
        event.preventDefault();
        if (validateStep()) {
           let result=await contact_us(data)
           if(result?.success){
            setData({
                email: "",
                subject: "",
                description:"",
                name: "",
                phone_number: "",
            })
            setFieldErrors({})
           }

        }
    }
    return (
        <div className='contact_us'>
            <div className='row m-0 p-0'>
                <div className='col-lg-6 col-md-12 col-12 p-0'>
                    <div className='inp_section p-5'>
                        <h2>Let's Talk About Everything!</h2>
                        <p className='mt-4'>Hello there! If you'd like to ask us something, you can get in touch with us here! We'd love to address any and all concerns you may have.</p>
                        <form onSubmit={submit}>
                            <div className='inp_login'>
                                <label>Name</label>
                                <div style={{ position: "relative" }}>
                                    <input value={data?.name} type='text' onChange={(e) => setData({ ...data, name: e.target.value })} placeholder='Full Name' />
                                </div>
                                {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}
                            </div>
                            <div className='inp_login'>
                                <label>Email</label>
                                <input value={data?.email} onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                            </div>
                            <div className='inp_login'>
                                <label>Subject</label>
                                <div style={{ position: "relative" }}>
                                    <input value={data?.subject} type='text' onChange={(e) => setData({ ...data, subject: e.target.value })} placeholder='General Inquiry' />
                                </div>
                                {fieldErrors?.subject && <span className='text_error'>{fieldErrors?.subject}</span>}
                            </div>
                            <div className='inp_login'>
                                <label>Contact</label>
                                <div className='contact_contry'>
                                    <div className='img_container'>
                                        <img src='./image/Group.png' alt='...' />
                                        <span>+92</span>
                                    </div>
                                    <input value={data?.phone_number} style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} onChange={(e) => setData({ ...data, phone_number: formatPakistaniPhoneNumber(e.target.value) })} type='number' placeholder='e.g 3324751060' />
                                </div>
                                {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                            </div>
                            <div className='inp_login'>
                                <label>Describe yourself</label>
                                <div style={{ position: "relative" }}>
                                    <textarea value={data?.description} onChange={(e) => setData({ ...data, description: e.target.value })} type='text' placeholder='Write here' />
                                </div>
                                {fieldErrors?.description && <span className='text_error' style={{top:"100%"}}>{fieldErrors?.description}</span>}
                            </div>
                            <button type='submit' style={{ width: "200px" }} className='Sign_inBtn'>Send your Question</button>
                        </form>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 col-12 p-0'>
                    <div className='contact_map p-3'>
                        <h4>Keep In Touch with Us</h4>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13604.362497224101!2d74.42700851609969!3d31.521670828933576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190f0edb1ad977%3A0xf6b165821d6a6dc!2sDHA%20Phase%208%20-%20Ex%20Park%20View%20Park%20View%20CHS%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1722356405588!5m2!1sen!2s" width="100%" height="486" style={{ border: "0", borderRadius: "5px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='location'></iframe>
                        <ul className='p-0 mt-3'>
                            <li><IoLogoWhatsapp className='icons' />0305-1115551</li>
                            <li><FaPhone className='icons' />0305-1115551</li>
                            <li><IoMailSharp className='icons' />social@pakistanproperty.com</li>
                            <li><FaBriefcase className='icons' />Mon-Sat 9:00am - 10:00pm</li>
                            <li><FaLocationDot className='icons' />Building No. 21, 3rd Floor, Block-CCA, DHA Phase 8 - Ex Park View Phase-8
                                Lahore, 54000.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner