import React from 'react'
import img from '../../Images/seo-banner-img.png'
function SeoSection() {
  return (
    <div className='container-fluid main_banner_home mt-5 '>
    <div style={{height:"372px" , overflow:'hidden'}} className='agent mt-5'>
      <div className='row m-0'>
<div className='col-md-6 col-12 p-0'>
<div>
            <img src={img} alt='...'/>
        </div>
</div>
<div className='col-md-6 col-12 p-0 h-100' style={{overflow:'auto'}}>
<div className='agen_content'>
            <h1>A Comprehensive Database of Properties</h1>
            <p>We have listings for all types of properties, from apartments and houses to villas and commercial properties, you’ll find everything in one place here at Pakistan Property</p>
            <h1>Veteran Agents With Deep Market Experience</h1>
            <p>Our wide network of real estate agents is associated with the best real estate agencies in Pakistan including Platinum and Titanium agencies.<br/><br/>They have deep market knowledge, negotiation skills and an eye for quality property research, they’ll get you the best property deals so you don’t have to play the waiting game</p>
            <h1>Advanced Search Tools</h1>
            <p>If you’re on the lookout for properties for sale in Pakistan then you’re in for a treat, our advanced search filters narrow down property search by location to property type, area length, building size and many other useful filters helping you find exactly what you envisioned when you first started your online property search.</p>
       
       
       
        </div>
</div>
      </div>
        
       
        <div className='agent_btn mt-5'>
        </div>
    </div>
</div>
  )
}

export default SeoSection