import React, { useEffect, useState } from 'react'
import { MdArrowOutward } from 'react-icons/md'
import { Link} from 'react-router-dom'
import { useAuth } from '../../Context/ContextProvider'
import NoReacodFound from '../NavBarFooter/NoReacodFound'
import img from '../../Images/dummy/placeholder.png'
import bahawalpur from '../../Images/Agents/Bahawalpur.jpeg'
import faislaabad from '../../Images/Agents/Faislaabad.jpg'
import chitral from '../../Images/Agents/chitral.jpg'
import multan from '../../Images/Agents/Mask group (2).png'
import daska from '../../Images/Agents/daska.jpg'
import kasur from '../../Images/Agents/kasur.jpg'
import hafizabad from '../../Images/Agents/hafizabad.jpg'
import gujranwala from '../../Images/Agents/gujranwala.jpg'
import narowal from '../../Images/Agents/narowal.jpg'
import sheikhupura from '../../Images/Agents/Sheikhupura.jpg'
import lahore from '../../Images/Agents/Rectangle 17.png'
import { FallbackImage } from '../FallbackImage'

function LastSection() {
  const { cityAgency } = useAuth()
  const [featureAgencyList, setFeatureAgencyList] = useState([])
  useEffect(() => {
      const fetchCart = async () => {
          try {
              let result = await cityAgency();
              if (result?.success) {
                  let propertyData = result?.data?.data?.agencies;
                  console.log(propertyData);
                  
                  setFeatureAgencyList(propertyData)

              }
          } catch (error) {
              console.error("An error occurred while fetching data:", error);
          }
      };

      fetchCart()
      // eslint-disable-next-line
  }, [])
  // const navigate=useNavigate()
  return (
    <div className='neighborhood_properties row container-fluid main_banner_home mt-3 px-3 mx-0'>
      <div className="heading mt-3">Browse Agencies by City</div>
      {featureAgencyList?.length?featureAgencyList?.map((item)=>{
        return(
          <div style={{cursor:"pointer"}} className='col-lg-4 col-md-6 col-12 my-2'>
        <div className='main_card'>
            <div className='img'>
            {item?.city==="Lahore"&&<FallbackImage src={lahore} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Daska"&&<FallbackImage src={daska} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Multan"&&<FallbackImage src={multan} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Faisalabad"&&<FallbackImage src={faislaabad} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Chitral"&&<FallbackImage src={chitral} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Bahawalpur"&&<FallbackImage src={bahawalpur} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Sheikhupura"&&<FallbackImage src={sheikhupura} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Kasur"&&<FallbackImage src={kasur} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Gujranwala"&&<FallbackImage src={gujranwala} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Narowal"&&<FallbackImage src={narowal} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Hafizabad"&&<FallbackImage src={hafizabad} fallbackSrc={img} alt={"...."}/>}
            </div>
            <div className='content_neignor'> 
            <h1>{item?.agency_profile_count}</h1>
            <h5>{item?.city}</h5>
            <Link to={`/agent-list/${item?.city}`}>View Agents <MdArrowOutward /></Link>
            </div>
        </div>
      </div>
        )
      }):<NoReacodFound/>}
 
    </div>
  )
}

export default LastSection
