import React from 'react';
import Slider from 'react-slick'; // React-Slick Carousel
import { IoMdCheckmark } from 'react-icons/io';
import { SlLocationPin } from 'react-icons/sl';
import img from '../../Images/Mask Group.png';
import img1 from '../../Images/logo.png';
import img2 from '../../Images/Group.png';
import img3 from '../../Images/Vector (1).png';
import img4 from '../../Images/Group 1000014144.png';
import img5 from '../../Images/Vector (2).png';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import svgImg from '../../Images/icons/Frame.svg'

const similarProjects = [
  {
    id: 1,
    title: "Beach Resort by Icon, Lahore",
    location: "Raiwind Road, Lahore",
    size: "5 Marla, 10 Marla, 1 Kanal",
    price: "PKR 68.9 Lakh to 7.82 Crore",
    isVerified: true,
    imgSrc: img,
  },
  {
    id: 2,
    title: "Bahria Town Apartments, Karachi",
    location: "Main Jinnah Avenue, Karachi",
    size: "2 Bed, 3 Bed, 4 Bed",
    price: "PKR 1.2 Crore to 5.5 Crore",
    isVerified: true,
    imgSrc: img,
  },
  {
    id: 3,
    title: "DHA Valley, Islamabad",
    location: "Expressway, Islamabad",
    size: "5 Marla, 8 Marla, 1 Kanal",
    price: "PKR 25 Lakh to 2 Crore",
    isVerified: true,
    imgSrc: img,
  },
  {
    id: 4,
    title: "Icon Tower, Lahore",
    location: "Gulberg, Lahore",
    size: "1 Bed, 2 Bed, 3 Bed Apartments",
    price: "PKR 50 Lakh to 1.8 Crore",
    isVerified: false,
    imgSrc: img,
  },
  {
    id: 5,
    title: "Capital Smart City, Islamabad",
    location: "Chakri Road, Islamabad",
    size: "5 Marla, 10 Marla, 1 Kanal",
    price: "PKR 30 Lakh to 3 Crore",
    isVerified: true,
    imgSrc: img,
  },
];

const alKareemProjects = [
  {
    id: 1,
    title: "Beach Resort by Icon, Lahore",
    location: "Raiwind Road, Lahore",
    size: "5 Marla, 10 Marla, 1 Kanal",
    price: "PKR 68.9 Lakh to 7.82 Crore",
    isVerified: true,
    imgSrc: img,
  },
  {
    id: 2,
    title: "Bahria Town Apartments, Karachi",
    location: "Main Jinnah Avenue, Karachi",
    size: "2 Bed, 3 Bed, 4 Bed",
    price: "PKR 1.2 Crore to 5.5 Crore",
    isVerified: true,
    imgSrc: img,
  },
  {
    id: 3,
    title: "DHA Valley, Islamabad",
    location: "Expressway, Islamabad",
    size: "5 Marla, 8 Marla, 1 Kanal",
    price: "PKR 25 Lakh to 2 Crore",
    isVerified: true,
    imgSrc: img,
  },
  {
    id: 4,
    title: "Icon Tower, Lahore",
    location: "Gulberg, Lahore",
    size: "1 Bed, 2 Bed, 3 Bed Apartments",
    price: "PKR 50 Lakh to 1.8 Crore",
    isVerified: false,
    imgSrc: img,
  },
  {
    id: 5,
    title: "Capital Smart City, Islamabad",
    location: "Chakri Road, Islamabad",
    size: "5 Marla, 10 Marla, 1 Kanal",
    price: "PKR 30 Lakh to 3 Crore",
    isVerified: true,
    imgSrc: img,
  },
];


const CustomPrevArrow = ({ onClick }) => (
  <button className="custom-prev-arrow" onClick={onClick}>
    <FiArrowLeft />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button className="custom-next-arrow" onClick={onClick}>
    <FiArrowRight />
  </button>
);

function SimilarsProjects() {
  const settings = {
    dots: false, 
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderProjectCard = (project) => (
    <div className='col-lg-4  col-8 p-2 '>
      <div className='project-card'>
        <img src={img} className='main_img' alt='...' />
        <div className='over_lay'></div>
        <div className='project-logo'>
          <div className='p-fold'>
            <div className='markit-project'>
              <div className='p-markit'>Marketed By</div>
              <div className='project-icon'>
                <img src={img1} alt='...' />
              </div>
            </div>
            {/* <div className='fold-card'>
        <span><AiOutlineFire/></span>
        <h3>Trending</h3>
        </div> */}
          </div>
          <div className='project-img'>
            <div className='pro-icon'>
              <img src={img5} alt='...' />
            </div>
            <div className='pro-icon'>
              <img src={img2} alt='...' />
            </div>
            <div className='pro-icon'>
              <img src={img3} alt='...' />
            </div>
            <div style={{ border: 'none' }} className='pro-icon'>
              <img src={img4} alt='...' />
            </div>
          </div>
        </div>
        <div style={{ top: "80px" }} className='position-relative'>
          <div className="position-absolute  pt-1 px-3"><img src={svgImg} alt='...' />POPULAR</div>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
          </svg>
        </div>
        <div className='project-heading'>
          <div className='tik'>
            <span style={{ marginLeft: '6px', marginTop: '-4px', color: ' #1C9093', fontSize: '16px' }}><IoMdCheckmark /></span>
            <h4>Verified</h4>
          </div>
          <h2>Beach Resort by Icon,<br />Lahore</h2>
          <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />Raiwind Road, Lahore</span>
          <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />5 Marla, 10 Marla, 1 Kanal</span>
          <div>
            <p>PKR  68.9 Lakh to 7.82 Crore</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="similar-projects">
        <div className="top-project px-4 my-4 pt-2">
          <h2>Similar Projects around abc</h2>
          <Slider {...settings}>
            {similarProjects.map(renderProjectCard)}
          </Slider>
        </div>
      </div>
      <div className="top-project px-4 mt-4 pt-4">
        <h2 className="mt-2 d-flex justify-content-lg-start justify-content-center">
          Similar Projects by Al Kareem Property
        </h2>
        <Slider {...settings}>
          {alKareemProjects.map((project) => renderProjectCard(project))}
        </Slider>
      </div>
    </>
  );
}

export default SimilarsProjects;
