import React, { useState } from 'react'
import { FaMinus, FaPhoneAlt, FaPlus, FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { GrMail } from 'react-icons/gr'
import { PiTrash, PiUsers } from 'react-icons/pi'
import { TbClockExclamation, TbCurrentLocation, TbLayoutGridAdd } from 'react-icons/tb'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AiFillCloseCircle } from 'react-icons/ai'
import Checkbox from '@mui/material/Checkbox';
import { teal } from '@mui/material/colors';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiExpandAlt } from 'react-icons/bi';
import { MdOutlineEdit } from 'react-icons/md'
import dummyImage from '../../Images/images.jfif'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px"
};
function AgencyStaff() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [password, setPassword] = useState(false)
    const [data,setData]=useState({
        name:"",
        email:"",
        password:"",
        landline:"",
        mobile:"",
        city_id:"",
        address:"",
        image:""
    })
    const [fieldErrors, setFieldErrors] = React.useState({})
    const validateStep = () => {
        let errors = {

        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!data.password) {
            errors.password = "Password is required.";
        } else if (data.password.length < 8 || data.password.length > 10) {
            errors.password = "Password must be between 8 and 10 characters long.";
        } else if (!hasSpecialCharacter(data.password)) {
            errors.password = "Password must contain at least one special character.";
        }
        if (!data.landline) {
            errors.landline = "landline is required.";
        }
        if (!data.city_id) {
            errors.city_id = "City is required.";
        }
        if (!data.address) {
            errors.address = "Address is required.";
        }
        if (!data.mobile) {
            errors.mobile = "Mobile is required.";
        }
        else if (!isValidPakistaniPhoneNumber(data.mobile)) {
            errors.mobile = "Please enter a valid Pakistan phone number.";
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const submit = (event) => {
        event.preventDefault();
        if (validateStep()) {
        }
    }
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (event) => {
            const base64String = event.target.result;
            setData({ ...data, image: base64String });
          };
    
          reader.readAsDataURL(file);
        }
      };
      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const hasSpecialCharacter = (password) => {
        // Regular expression for checking special characters
        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
        return specialCharacterRegex.test(password);
    };
    const formatPakistaniPhoneNumber = (phoneNumber) => {

        let phone = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

        // Handle different input formats
        if (phone.startsWith('92')) {
            phone = phone.slice(2);
        } else if (phone.startsWith('0')) {
            phone = phone.slice(1);
        }

        if (phone.length === 10) {
            return `+92${phone}`;
        }

        return phoneNumber; // Return original input if it doesn't match the expected formats
    };
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters except '+'
        // eslint-disable-next-line
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

        // Log the cleaned phone number
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
        // Regular expression for Pakistani phone number
        // const pakistaniPhoneRegex = /^\+92\d{11}$/;

        // Validate phone number format and length
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

        return isValid;
    };
    return (
        <div className='agency_staff add_propertey_main'>
            <div className='content_box'>
                <div className='locationPurpos'>
                    <div className='main_profile'>
                        <div className='d-flex row m-0 w-100'>
                            <div className="col-lg-9 col-12 p-0 d-flex">
                            <div className='profile_section'>
                                <div className='profile_img'>
                                    <img style={{width:"100%",height:"100%",objectFit:"cover"}} src={dummyImage} alt='...'/>
                                </div>
                                <div className='user_data'>
                                    <h6 className='p-0 m-0'>USMAN AFZAL</h6>
                                    <p className='p-0 my-1'><FaPhoneAlt style={{ fontSize: "13px", color: "#1C9093", marginRight:'5px' }} />
                                        +923234742445</p>
                                    <p className='p-0 my-1'><GrMail style={{ fontSize: "13px", color: "#1C9093", marginRight:'5px' }} />pakistanproperty.com@gmail.com</p>
                                </div>
                            </div>
                            <div className='profile_quota px-sm-3 px-0 mt-sm-0 mt-2'>
                                <h6 className='m-0 p-0 d-sm-block d-none'>Quota</h6>
                                <p className='p-0 my-1 d-sm-block d-none'>Expiry Date</p>
                                <p className='p-0 my-1 d-flex align-items-center d-sm-block d-none'><TbClockExclamation style={{ fontSize: "13px", color: "#1C9093", marginRight: ".2rem" }} />Sep 23, 2024</p>
                            </div>
                            </div>
                            <div className="col-lg-3 col-12 mt-lg-0 mt-2 p-0 d-flex justify-content-sm-end justify-content-between" style={{flexWrap:'wrap'}}>
                            <div className='d-flex profile_quota me-3'>
                            <h6 className='m-0 p-0 d-sm-none d-block'>Quota</h6>
                                <div className="d-flex">
                                <p className='p-0  d-sm-none d-inline'>Expiry Date:</p>
                                <p className='p-0 d-flex align-items-center d-sm-none d-inline ms-1'><TbClockExclamation style={{ fontSize: "13px", color: "#1C9093", marginRight: ".2rem" }} />Sep 23, 2024</p>
                                </div>
                                </div>
                            <div className='d-flex align-items-center'>
                            <button onClick={handleOpen} className='add_staff_btn'>Add agency User <PiUsers className='mx-2' /></button>
                        </div>
                            </div>
                           
                        </div>
                        
                    </div>
                </div>
                <div className='locationPurpos my-3'>
                    <div className='listing_table'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Quota</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Zahoor Ali</td>
                                    <td>zahoor.ali@gmail.com</td>
                                    <td>260</td>
                                    <td>
                                        <div className='d-flex'>

                                            <div onClick={handleOpen} className='icons'>

                                                <MdOutlineEdit />
                                            </div>

                                            <div onClick={handleOpen1} className='icons'>

                                                <TbLayoutGridAdd />
                                            </div>

                                            <div className='icons'>

                                                <PiTrash />
                                            </div>
                                        </div></td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='modal_feature p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h4 className='heading'>Add User</h4>
                            <button onClick={handleClose} style={{ background: "none", border: "none", color: "#ECECEC", fontSize: "20px" }}><AiFillCloseCircle /></button>
                        </div>
                        <div className='feature_list' style={{ height: "550px" }}>
                            <div className='row my-2'>
                                <div className='col-6'>
                                    <div className='inp_login'>
                                        <label>Full Name</label>
                                        <input type='text' onChange={(e)=>setData({...data,name:e.target.value})} placeholder='Name' />
                                        {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='inp_login'>
                                        <label>Email</label>
                                        <input onChange={(e)=>setData({...data,email:e.target.value})} type='email' placeholder='Email' />
                                    {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='inp_login'>
                                        <label>Enter New Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input onChange={(e)=>setData({...data,password:e.target.value})} type={`${password ? "text" : "password"}`} placeholder='Password' />
                                            {!password ? <FaRegEyeSlash onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                                <FaRegEye onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                                        </div>
                                        {fieldErrors?.password && <span className='text_error'>{fieldErrors?.password}</span>}
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='inp_login'>
                                        <label>Landline</label>
                                        <div className='contact_contry'>
                                            <div className='img_container'>
                                                <img src='../image/Group.png' alt='...' />
                                                <span>+92</span>
                                            </div>
                                            <input onChange={(e)=>setData({...data,landline:e.target.value})} style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} type='number' placeholder='e.g 3324751060' />
                                        </div>
                                        {fieldErrors?.landline && <span className='text_error'>{fieldErrors?.landline}</span>}    
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='inp_login'>
                                        <label>Mobile</label>
                                        <div className='contact_contry'>
                                            <div className='img_container'>
                                                <img src='../image/Group.png' alt='...' />
                                                <span>+92</span>
                                            </div>
                                            <input onChange={(e)=>setData({...data,mobile:formatPakistaniPhoneNumber(e.target.value)})} style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} type='number' placeholder='e.g 3324751060' />
                                        </div>
                                        {fieldErrors?.mobile && <span className='text_error'>{fieldErrors?.mobile}</span>}    
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='inp_login'>
                                        <label>City</label>
                                        <select onChange={(e)=>setData({...data,city_id:e.target.value})} style={{ padding: ".7rem" }}>
                                            <option>Choose City</option>
                                        </select>
                                        {fieldErrors?.city_id && <span className='text_error'>{fieldErrors?.city_id}</span>}    
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='inp_login'>
                                        <label>Address</label>
                                        <div className='d-flex align-items-center' style={{ border: "1px solid lightgray", borderRadius: "5px", padding: "0 .5rem" }}>
                                            <TbCurrentLocation />
                                            <input onChange={(e)=>setData({...data,address:e.target.value})} style={{ border: "none" }} type='text' placeholder='Location Detail, e,g. No. Street' />
                                        </div>
                                        {fieldErrors?.address && <span className='text_error'>{fieldErrors?.address}</span>}    
                                    </div>
                                </div>
                            </div>
                            <div className='inp_login my-2'>
                                <label>Upload a picture</label>
                            </div>
                            <div className='propertyimage'>
                                <div className='image_section py-3 px-3'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12 col-12'>
                                            <div className='d-flex flex-column justify-content-center h-100'>
                                                <label className='btn1 w-100' for="upload_image">
                                                Upload Image
                                                </label>
                                                <button className='btn2 w-100 mt-2'>Image Bank</button>
                                                <input onChange={handleFileInputChange} hidden type='file' id='upload_image' />
                                            </div>
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-12'>
                                            <div style={{ borderRadius: "5px", display: "flex", alignItems: "center" }}>
                                            {data?.image&&    <div className='image_main p-0'>
                                                    <img src={data?.image} alt='...' />
                                                    <div style={{ position: "absolute", background: "white", left: "10px", top: "5px", height: "25px", width: "25px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                            sx={{
                                                                color: teal[800],
                                                                '&.Mui-checked': {
                                                                    color: teal[600],
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ position: "absolute", background: "white", right: "10px", top: "5px", height: "20px", width: "20px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <RiDeleteBinLine style={{ color: "#1C9093" }} />
                                                    </div>
                                                    <div style={{ position: "absolute", background: "white", right: "10px", bottom: "5px", height: "20px", width: "20px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <BiExpandAlt style={{ color: "#1C9093" }} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='model_footer d-flex justify-content-between pt-3'>
                            <button onClick={submit} className='add_btn'>Confirm</button>
                            <button onClick={handleClose} className='cancel_btn'>Cancel</button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='modal_feature p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h4 className='heading'>Manage Quota for Zahoor Ali</h4>
                            <button onClick={handleClose1} style={{ background: "none", border: "none", color: "#ECECEC", fontSize: "20px" }}><AiFillCloseCircle /></button>
                        </div>
                        <div className='feature_list' style={{ height: "550px" }}>
                            <div className='available_to my-3'>
                                <h6>Available to be Assigned</h6>
                                <div className='d-flex'>
                                    <div className='sections text-center'>
                                        <p>Refresh <br />Listing</p>
                                        <span>2</span>
                                    </div>
                                    <div className='sections text-center'>
                                        <p>Hot <br />Listing</p>
                                        <span>2</span>
                                    </div>
                                    <div className='sections text-center'>
                                        <p>Super Hot <br />Listing</p>
                                        <span>2</span>
                                    </div>
                                    <div className='sections text-center'>
                                        <p>Story Ad <br />Listing</p>
                                        <span>2</span>
                                    </div>
                                    <div className='sections text-center border-none'>
                                        <p>Verified <br />Photography Credits</p>
                                        <span>2</span>
                                    </div>

                                </div>
                            </div>
                            <div className='listing_manage'>
                                <div className='d-flex'>
                                    <h6>Current Listings and Credits</h6>
                                    <h6 className='mx-3'>Add or Remove</h6>
                                </div>
                                <div className='row align-items-center my-2'>
                                    <div style={{ border: "none" }} className='col-3 px-3'>
                                        <p  className='m-0'>Refresh<br />Listing</p>
                                        <span>2</span>
                                    </div>
                                    <div className='col-3 d-flex justify-content-end'>
                                        <button><FaMinus /></button>
                                    </div>
                                    <div className='col-3'>
                                        <input placeholder='0' type='number' />
                                    </div>
                                    <div className='col-3'>
                                        <button><FaPlus /></button>
                                    </div>
                                </div>
                                <div className='row align-items-center my-2'>
                                    <div style={{ border: "none" }} className='col-3 px-3'>
                                        <p  className='m-0'>Hot<br />Listing</p>
                                        <span>2</span>
                                    </div>
                                    <div className='col-3 d-flex justify-content-end'>
                                        <button><FaMinus /></button>
                                    </div>
                                    <div className='col-3'>
                                        <input placeholder='0' type='number' />
                                    </div>
                                    <div className='col-3'>
                                        <button><FaPlus /></button>
                                    </div>
                                </div>
                                <div className='row align-items-center my-2'>
                                    <div style={{ border: "none" }} className='col-3 px-3'>
                                        <p  className='m-0'>Super Hot<br />Listing</p>
                                        <span>2</span>
                                    </div>
                                    <div className='col-3 d-flex justify-content-end'>
                                        <button><FaMinus /></button>
                                    </div>
                                    <div className='col-3'>
                                        <input placeholder='0' type='number' />
                                    </div>
                                    <div className='col-3'>
                                        <button><FaPlus /></button>
                                    </div>
                                </div>
                                <div className='row align-items-center my-2'>
                                    <div style={{ border: "none" }} className='col-3 px-3'>
                                        <p  className='m-0'>Story Ad</p>
                                        <span>2</span>
                                    </div>
                                    <div className='col-3 d-flex justify-content-end'>
                                        <button><FaMinus /></button>
                                    </div>
                                    <div className='col-3'>
                                        <input placeholder='0' type='number' />
                                    </div>
                                    <div className='col-3'>
                                        <button><FaPlus /></button>
                                    </div>
                                </div>
                                <div className='row align-items-center my-2'>
                                    <div style={{ border: "none" }} className='col-3 px-3'>
                                        <p className='m-0'>Verified<br />Photography Credits</p>
                                        <span>2</span>
                                    </div>
                                    <div className='col-3 d-flex justify-content-end'>
                                        <button><FaMinus /></button>
                                    </div>
                                    <div className='col-3'>
                                        <input placeholder='0' type='number' />
                                    </div>
                                    <div className='col-3'>
                                        <button><FaPlus /></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='model_footer d-flex justify-content-between pt-3'>
                            <button className='add_btn'>Confirm</button>
                            <button onClick={handleClose1} className='cancel_btn'>Cancel</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default AgencyStaff