import React, { useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HomePage/Banner'
import PopularProperties from '../Camponent/HomePage/PopularProperties'
import ListingProperty from '../Camponent/HomePage/ListingProperty'
import OurProject from '../Camponent/HomePage/OurProject'
import Footer from '../Camponent/NavBarFooter/Footer'
import SeoSection from '../Camponent/HomePage/SeoSection'
import NeighborhoodProperties from '../Camponent/HomePage/NeighborhoodProperties'

function HomePage() {
  const [propertyList,setProperty]=useState([])
  const [propertyListAll,setPropertyAll]=useState([])
  return (
    <div>
           {/* <ClientProject/> */}
        {/* <Agent/> */}
        <NavBar/>
        <Banner setPropertyAll={setPropertyAll} setPropertyData={setProperty}/>
        <PopularProperties setPropertyAll={setPropertyAll} propertyListAll={propertyListAll} propertyList={propertyList}  setPropertyData={setProperty}/>
        <ListingProperty data={propertyList}/>
        <OurProject/>
   
        <NeighborhoodProperties/>
        <SeoSection/>
        <Footer/>

    </div>
  )
}

export default HomePage
