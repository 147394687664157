import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import { teal } from '@mui/material/colors';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function Main({ show, data, setData, value ,newData}) {
    return (
        <>
            <div className='row mb-4'>
                {show?.map((item) => {
                    if (item.type === "select") {
                        const selectedOption = data.amenities?.find(
                            (amenity) => amenity.amenity_category_id === value?.amenity_category_id
                        )?.data?.find(
                            (amenity) => amenity.amenity_id === item.id
                        )?.selected_options[0] || "";
                        return (
                            <div className='col-6 d-flex justify-content-between align-items-center my-2'>
                                <label>{item?.label}</label>
                                <select value={selectedOption} onChange={(e) => {
                                    let selectedOption = e.target.value;
                                    let obj_data = {
                                        amenity_category_id: value?.amenity_category_id,
                                        category_name: value?.name,
                                        property_type_id: newData?.property_type,
                                        data: [{
                                            amenity_id: item.id,
                                            type: item?.type,
                                            lable: item?.label,
                                            selected_options: [selectedOption],
                                        }]
                                    };

                                    // Check if `amenity_category_id` already exists in `amenities`
                                    const existingCategoryIndex = data.amenities?.findIndex(
                                        (amenity) => amenity.amenity_category_id === obj_data.amenity_category_id
                                    );

                                    if (existingCategoryIndex !== -1) {
                                        // Check if `data` array exists and is an array
                                        const existingCategory = data.amenities[existingCategoryIndex];
                                        const existingData = existingCategory.data || [];

                                        // Check if `amenitiy_id` exists within its data
                                        const existingAmenityIndex = existingData.findIndex(
                                            (amenity) => amenity.amenity_id === item.id
                                        );

                                        if (existingAmenityIndex !== -1) {
                                            // Update the existing amenity
                                            existingData[existingAmenityIndex].selected_options = [selectedOption];
                                            data.amenities[existingCategoryIndex].data = existingData;
                                        } else {
                                            // Add the new amenity within the same category
                                            existingData.push({
                                                amenity_id: item.id,
                                                type: item?.type,
                                                lable: item?.label,
                                                selected_options: [selectedOption],
                                            });
                                            data.amenities[existingCategoryIndex].data = existingData;
                                        }

                                        setData({ ...data });
                                    } else {
                                        // If `amenity_category_id` doesn't exist, add the new category and amenity
                                        setData({
                                            ...data,
                                            amenities: [...data.amenities, obj_data]
                                        });
                                    }
                                }}>
                                    {item?.option&&item?.option?.split(',')?.map((items) => {
                                        <option hidden>Select option</option>
                                        return (
                                            <option value={items}>{items}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        )
                    }
                    if (item.type === "text") {
                        const inputValue = data.amenities?.find(
                            (amenity) => amenity.amenity_category_id === value?.amenity_category_id
                        )?.data?.find(
                            (amenity) => amenity.amenity_id === item.id
                        )?.selected_options[0] || "";
                        return (
                            <div className='col-6 d-flex justify-content-between align-items-center my-2'>
                                <label>{item?.label}</label>
                                <input value={inputValue} onChange={(e) => {
                                    let selectedOption = e.target.value;
                                    let obj_data = {
                                        amenity_category_id: value?.amenity_category_id,
                                        category_name: value?.name,
                                        property_type_id: newData?.property_type,
                                        data: [{
                                            amenity_id: item.id,
                                            lable: item?.label,
                                            type: item.type,
                                            selected_options: [selectedOption],
                                        }]
                                    };

                                    // Check if `amenity_category_id` already exists in `amenities`
                                    const existingCategoryIndex = data.amenities?.findIndex(
                                        (amenity) => amenity.amenity_category_id === obj_data.amenity_category_id
                                    );

                                    if (existingCategoryIndex !== -1) {
                                        // Check if `data` array exists and is an array
                                        const existingCategory = data.amenities[existingCategoryIndex];
                                        const existingData = existingCategory.data || [];

                                        // Check if `amenitiy_id` exists within its data
                                        const existingAmenityIndex = existingData.findIndex(
                                            (amenity) => amenity.amenity_id === item.id);

                                        if (existingAmenityIndex !== -1) {
                                            // Update the existing amenity
                                            existingData[existingAmenityIndex].selected_options = [selectedOption];
                                            data.amenities[existingCategoryIndex].data = existingData;
                                        } else {
                                            // Add the new amenity within the same category
                                            existingData.push({
                                                amenity_id: item.id,
                                                type: item.type,
                                                lable: item?.label,
                                                selected_options: [selectedOption],
                                            });
                                            data.amenities[existingCategoryIndex].data = existingData;
                                        }

                                        setData({ ...data });
                                    } else {
                                        // If `amenity_category_id` doesn't exist, add the new category and amenity
                                        setData({
                                            ...data,
                                            amenities: [...data.amenities, obj_data]
                                        });
                                    }
                                }} type='text' placeholder='Enter Data' />
                            </div>
                        )
                    }
                    return null
                })}

            </div>


            <div className='row mb-2'>
                {show?.map((item) => {
                    if (item.type === "checkbox") {
                        const isChecked = data.amenities?.find(
                            (amenity) => amenity.amenity_category_id === value?.amenity_category_id
                        )?.data?.find(
                            (amenity) => amenity.amenity_id === item.id
                        )?.selected_options[0] || false;
                        return (
                            <div className='col-6 d-flex align-items-center'>
                                <Checkbox
                                    {...label}
                                    checked={isChecked}
                                    onChange={(e) => {
                                        let selectedOption = e.target.checked;
                                   
                                        
                                        let obj_data = {
                                            amenity_category_id: value?.amenity_category_id,
                                            category_name: value?.name,
                                            property_type_id: newData?.property_type,
                                            data: [{
                                                amenity_id: item.id,
                                                type: item.type,
                                                lable: item?.label,
                                                selected_options: [selectedOption],
                                            }]
                                        };

                                        // Check if `amenity_category_id` already exists in `amenities`
                                        const existingCategoryIndex = data.amenities?.findIndex(
                                            (amenity) => amenity.amenity_category_id === obj_data.amenity_category_id
                                        );

                                        if (existingCategoryIndex !== -1) {
                                            // Check if `data` array exists and is an array
                                            const existingCategory = data.amenities[existingCategoryIndex];
                                            const existingData = existingCategory.data || [];

                                            // Check if `amenitiy_id` exists within its data
                                            const existingAmenityIndex = existingData.findIndex(
                                                (amenity) => amenity.amenity_id === item.id
                                            );

                                            if (existingAmenityIndex !== -1) {
                                                // Update the existing amenity
                                                existingData[existingAmenityIndex].selected_options = [selectedOption];
                                                data.amenities[existingCategoryIndex].data = existingData;
                                            } else {
                                                // Add the new amenity within the same category
                                                existingData.push({
                                                    amenity_id: item.id,
                                                    type: item.type,
                                                    lable: item?.label,
                                                    selected_options: [selectedOption],
                                                });
                                                data.amenities[existingCategoryIndex].data = existingData;
                                            }

                                            setData({ ...data });
                                        } else {
                                            // If `amenity_category_id` doesn't exist, add the new category and amenity
                                            setData({
                                                ...data,
                                                amenities: [...data.amenities, obj_data]
                                            });
                                        }
                                    }}
                                    sx={{
                                        color: teal[800],
                                        '&.Mui-checked': {
                                            color: teal[600],
                                        },
                                    }}
                                />
                                <label>{item?.label}</label>
                            </div>
                        )
                    }
                    return null
                })}


            </div>

        </>
    )
}
export default Main
