import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/ContextProvider';
import { MdRemoveShoppingCart } from 'react-icons/md';
import img from '../../Images/image.png'
import img1 from '../../Images/image.svg'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { RiAttachment2 } from 'react-icons/ri';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    boxShadow: 24,
};
function Payment() {
    const { craeteOrder, loading, getCart,payLaterOrder,formatPriceWithCommas,bankDetail } = useAuth()
    const [cartData, setCartData] = useState([])
    const [cartData1, setCartData1] = useState([])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [lodingFor, setLoadingFor] = useState("")
    const [fileName, setFileName] = useState("")
    const [data,setData]=useState({
        image:"",
        order_status_code:""
    })
    const [accounts,setAccounts]=useState([])
    useEffect(() => {

        const fetchCart = async () => {
            try {
                let result = await getCart();
                if (result?.success) {
                    let array = []
                    let array1 = []
                    result?.data?.data?.cart?.products?.filter((item) => {
                        if (item?.type == "credits") {
                            array1.push(item)
                        }
                        else {
                            array.push(item)
    
                        }
                    })
                    setCartData(array);
                    setCartData1(array1);
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
      
        const fetchCart1 = async () => {
            try {
                let result = await bankDetail();
                if (result?.success) {
                  setAccounts(result?.data?.data?.accounts)
                  
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        fetchCart();
        fetchCart1()
        // eslint-disable-next-line
    }, []);
    const [fieldErrors, setFieldErrors] = React.useState({})

    const validateStep = () => {
        let errors = {

        };
        if (!data.image) {
            errors.image = "Image is required.";
        }
   
       
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const navigate = useNavigate()
    const submit = async () => {
        if(validateStep()){
            setLoadingFor("pay now")
            let result=await craeteOrder(data)
            if(result?.success){
              handleClose()
              navigate("/agent/order-history")
              setData({
                  image:"",
                  order_status_code:""
              })
              setFileName("")
            }
        }
     
    }
    const handleFileChange = (event) => {
        let file = event.target.files[0]
        setFileName(file?.name || "");
        setData({...data,image:file,order_status_code:"PA"});

    }
    return (
        <div className='row m-0'>
            <div className='buy_product add_propertey_main account_detail_main'>
                <div className='content_box'>
                    <h2>Bank Transfer</h2>
                    <div className='locationPurpos'>
                        <h3 className='heading_mian'>Please pay your amount at given below banks</h3>
                        <div className='d-flex justify-content-between'>
                            <div>
                                {accounts?.map((item)=>{
                                    return(
                                        <div className='account_detail'>
                                        <div className='title d-flex align-items-center mb-3'>
                                            <div className='bank_img'>
                                                <img src={item?.logo} alt='bank' />
    
                                            </div>
                                            <div className='mx-2'>
                                                <h6 className='m-0'>{item?.name}</h6>
    
                                            </div>
                                        </div>
    
                                        <div className='d-flex detail_box align-items-center'>
                                            <div style={{ width: "150px" }}>
                                                <h6 className='m-0 p-0'>Account Title</h6>
    
                                            </div>
                                            <div>
                                                <p className='m-0 p-0'>{item?.account_title}</p>
    
                                            </div>
                                        </div>
                                        <div className='d-flex detail_box align-items-center'>
                                            <div style={{ width: "150px" }}>
                                                <h6 className='m-0 p-0'>Account No.</h6>
    
                                            </div>
                                            <div>
                                                <p className='m-0 p-0'>{item?.account_number}</p>
    
                                            </div>
                                        </div>
                                        <div className='d-flex detail_box align-items-center'>
                                            <div style={{ width: "150px" }}>
                                                <h6 className='m-0 p-0'>IBAN</h6>
    
                                            </div>
                                            <div>
                                                <p className='m-0 p-0'>{item?.iban}</p>
    
                                            </div>
                                        </div>
    
                                    </div>
                                    )
                                })}
        
                            </div>
                            <div style={{ width: " 324px" }} className='locationPurpos order_summery py-0 px-2'>
                                <h6 className='m-0 heading'>Amount Detail</h6>
                                <hr className='p-0 m-0' />
                                {/* {cartData?.length ? <div className='cart_item pb-3'>
                                    {cartData?.map((item) => {
                                        return (
                                            <div className='d-flex justify-content-between'>
                                                <span>{item?.name} ({item?.quantity}X)</span><span>Rs {item?.price}</span>
                                            </div>
                                        )
                                    })}
                                    <hr className='p-0 m-0' />
                                    <div className='d-flex justify-content-between total'>
                                        <h6>Total</h6><span>Rs {cartData.reduce((acc, item) => acc + (item.price * item.quantity), 0)}</span>
                                    </div>
                                  
                                </div> : <div className='no_cart'>
                                    <MdRemoveShoppingCart className='icon' />
                                    <span className='mt-3'>No Item(s) in Cart</span>
                                    <span>Rs 00</span>
                                </div>} */}
                                {cartData?.length || cartData1?.length ? <>
                        <div className='cart_item pb-3'>
                        {cartData?.length ? <>
                                {/* <div className='d-flex justify-content-between'>
                                    <span style={{ fontWeight: "700", fontSize: "16px" }}>Listing</span>
                                </div> */}
                                {cartData?.map((item) => {

                                    return (
                                        <div className='d-flex justify-content-between cart_list'>
                                            {item?.name === "Listing" ? <><span>{item?.name} ({item?.quantity})</span><span>Rs {formatPriceWithCommas(item?.price)}</span></> : <>
                                                <div className='mt-2'>
                                                    <span>{item?.name}</span>
                                                    <p style={{ padding: "0", margin: "0", fontSize: "11px" }}>{item?.name?.replace("Listing", "Credits")} <span>({item?.quantity}X)</span></p>
                                                    <p style={{ padding: "0", margin: "0", fontSize: "11px" }}>{"Listing"} <span>({item?.quantity}X)</span></p>
                                                </div>
                                                <span>Rs {formatPriceWithCommas(item?.price)}</span>

                                            </>}

                                        </div>
                                    )

                                })}</> : ""}

                            {cartData1?.length ? <>
                                {/* <div className='d-flex justify-content-between'>
                                    <span style={{ fontWeight: "700", fontSize: "16px" }}>Credits</span>
                                </div> */}
                                {cartData1?.map((item) => {
                                    return (
                                        <div className='d-flex justify-content-between cart_list'>
                                            <span>{item?.name} ({item?.quantity})</span><span>Rs {formatPriceWithCommas(item?.price)}</span>
                                        </div>
                                    )
                                })}
                            </> : ""}
                            <hr className='p-0 m-0' />
                            <div className='d-flex justify-content-between total'>
                                <h6>Total</h6><span style={{lineHeight:"0"}}>Rs {formatPriceWithCommas([...cartData,...cartData1]?.reduce((acc, item) => acc + (item.price * item.quantity), 0))}</span>
                            </div>
                            <button onClick={async () => {
                                        handleOpen()
                                    }}>Pay Now</button>
                                    <button className='mt-2' onClick={async () => {
                                        setLoadingFor("pay later")

                                        let result = await payLaterOrder()
                                        if (result?.success) {
                                            navigate("/agent/order-history")
                                        }
                                    }}>{loading && lodingFor === "pay later" ? <div className="spinner-border text-light button_loading" role="status">

                                    </div> : "Pay Later"}</button>
                        </div>
                    </>
                        : <div className='no_cart'>
                            <MdRemoveShoppingCart className='icon' />
                            <span className='mt-3'>No Item(s) in Cart</span>
                            <span>Rs 00</span>
                        </div>}
                                
                            </div>
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div style={{ background: "white" }} className='modal_feature payment_modal p-3'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='heading'>Add User</h4>
                                        <button onClick={handleClose} style={{ background: "none", border: "none", color: "#ECECEC", fontSize: "20px" }}><AiFillCloseCircle /></button>
                                    </div>
                                    <hr className='p-0 m-0' />
                                    <h6 className='p-0 m-0'>Submit your payment receipt to complete the order</h6>
                                    <p className='p-0 m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text.</p>
                                    <div className='inp_login'>
                                        <label>Attach File</label>
                                        <div style={{ position: "relative" }}>
                                            <input onChange={handleFileChange} id='receipt' type="file" hidden />
                                            <input value={fileName} onClick={() => document.getElementById('receipt').click()} readOnly className='ps-5' type="text" placeholder='Attach File' />
                                            <RiAttachment2 style={{ position: "absolute", left: "15px", top: "10px", fontSize: "18px" }} />
                                            <label for="receipt" className='attach_lable' style={{ position: "absolute", right: "20px", top: "7px" }} >Attach File</label>
                                        </div>
                                        {fieldErrors?.image && <span className='text_error'>{fieldErrors?.image}</span>}
                                    </div>
                                    <div className='model_footer d-flex justify-content-between pt-3'>
                                        <button onClick={submit} className='add_btn'>{loading && lodingFor === "pay now" ? <div className="spinner-border text-light button_loading" role="status">

                                        </div> : "Confirm"}</button>
                                        <button onClick={handleClose} className='cancel_btn'>Cancel</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                    </div>

                </div>
            </div>

        </div>

    )
}

export default Payment