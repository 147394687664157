import React, { useEffect } from 'react';
import img from '../../Images/AgentProfile/Group 1000014158.png';
import flag from '../../Images/listing/pak.png';
import imgs from '../../Images/dummy/placeholder.png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import videoImg from '../../Images/dummy/vidioImage.jpg'
import { FaPhoneAlt } from 'react-icons/fa';
import { FallbackImage } from '../FallbackImage';
import { IoChevronBackOutline, IoChevronForwardOutline, IoClose } from 'react-icons/io5';
import Slider from 'react-slick';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    boxShadow: 24,
    outline: 'none'
};

function Card({ setOpen, open, profileData ,handleOpen}) {
    const [error, setError] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [radio, setRadio] = useState('Buyer/Tenant');
    const [activeDiv, setActiveDiv] = useState('div1');
    const [activeImg, setActiveImg] = useState();
    const [currentDiv, setCurrentDiv] = useState('original')
    const [data, setData] = useState({
        name: '',
        email: '',
        contact: '',
        message: '',
        other: '',
    });

   
    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, contact, message, other } = data;
        if (!name || !email || !contact || !message || (radio === 'Other' && !other)) {
            setError(true);
        } else {
            setError(false);
            setData({
                name: '',
                email: '',
                contact: '',
                message: '',
                other: '',
                radio: ''
            });
            setCheckbox(false);
            setRadio('Buyer/Tenant');
            setActiveImg()
        }
    };

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setRadio(value);

    };

    const handleCheckbox = (e) => {
        setCheckbox(e.target.checked);
    };

    const handleClick = (div) => {
        setActiveDiv(div);
    };

    const handleImageClick = (img) => {
        setActiveImg(img);
    };

    const handleChange = () => {
        setCurrentDiv('new')
    }

    const handleDefault = () => {
        setCurrentDiv('original')
    }
    useEffect(() => {
        if (profileData?.images?.length) {
            setActiveImg(profileData?.images?.length ? profileData?.images[0]?.url : img)
        }
    }, [profileData])
    const [activeVideo, setActiveVideo] = useState(profileData?.video_links[0] || ""); // Default to the first video

    const handleVideoClick = (url) => {
        setActiveVideo(url); // Update the main video URL
    };
    const isYouTubeVideo = (url) =>
        url.includes("youtube.com") || url.includes("youtu.be");
    const getEmbedUrl = (url) => {
        if (url.includes("youtube.com/watch?v=")) {
            const videoId = url.split("v=")[1].split("&")[0];
            return `https://www.youtube.com/embed/${videoId}`;
        }
        return url; // Return the URL as-is for non-YouTube videos
    };
    useEffect(() => {
        if (profileData?.video_links?.length) {
            handleVideoClick(profileData?.video_links[0])
        }
    }, [profileData?.video_links])
    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button
                className="slick-prev custom-arrow"
                onClick={onClick}
                style={{
                    borderRadius: '50%',
                    fontSize: '20px',
                    position: 'absolute',
                    top: '50%',
                    left: '5px',
                    zIndex: 1,
                    transform: 'translateY(-50%)',
                }}
            >
                <IoChevronBackOutline />
            </button>
        );
    };
    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <button
                className="slick-next custom-arrow"
                onClick={onClick}
                style={{

                    borderRadius: '50%',
                    fontSize: '20px',
                    position: 'absolute',
                    top: '50%',
                    right: '15px',
                    zIndex: 1,
                    transform: 'translateY(-50%)',
                }}
            >
                <IoChevronForwardOutline />
            </button>
        );
    };
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },

        ],
    };
    function formatPrice(value) {
        if (value >= 10000000) {
            // Convert to Crore
            return (value / 10000000).toFixed(2) + ' Crore';
        } else if (value >= 100000) {
            // Convert to Lakh
            return (value / 100000).toFixed(2) + ' Lakh';
        } else if (value >= 1000) {
            // Convert to Thousand
            return (value / 1000).toFixed(2) + ' Thousand';
        } else {
            // Less than 1000, show as is
            return value?.toString();
        }
    }
    return (
        <div>

            <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box sx={style}>
                    <div style={{ height: "560px", borderRadius: "5px", backgroundColor: "white", padding: "12px 0 16px 0" }}>
                        <div className="head-box px-4">
                            <div className="heading">Gallery & Map</div>
                            <button onClick={() => setOpen(false)} className='close-btn' ><IoClose /></button>
                        </div>
                        <hr className='mt-2 mb-0' />
                        <div className="row m-0 p-0">
                            <div className="col-6 ps-4 pt-2">
                                <div className="gallery-img-card">
                                    <div className="row m-0 "  >
                                        <div className="col-4 p-0">
                                            <div className={`tab ${activeDiv === 'div1' ? 'active' : 'dis-active'} text-center`} onClick={() => { handleClick('div1'); handleDefault(); }} id='div1'>  Photos ({profileData?.images?.length}) </div>
                                        </div>
                                        <div className="col-3 p-0">
                                            <div className={`tab ${activeDiv === 'div2' ? 'active' : 'dis-active'} text-center`} onClick={() => { handleClick('div2'); handleChange(); }} id='div2'> Videos ({profileData?.video_links?.length})</div>
                                        </div>
                                        <div className="col-3 p-0">
                                            <div className={`tab ${activeDiv === 'div3' ? 'active' : 'dis-active'} text-center`} onClick={() => { handleClick('div3'); handleChange(); }} id='div2'> Map </div>
                                        </div>
                                        <div className="col-5 p-0" style={{ borderBottom: "1.5px solid #C7C8C9" }} ></div>

                                    </div>
                                    {activeDiv === 'div1' &&
                                        <>
                                            <div className="row m-0 d-flex justify-content-center">
                                                <div className="col-12 p-0 d-flex justify-content-center">
                                                    <div className="img-box my-4" style={{ height: `${profileData?.images?.length > 1 ? "334px" : "394px"}` }}>
                                                        <FallbackImage
                                                            src={activeImg}
                                                            alt={"imgae"}
                                                            fallbackSrc={imgs}
                                                            className="w-100 h-100"
                                                            style={{ objectFit: 'cover' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {profileData?.images?.length > 1 && <div className="main-img-box">
                                                {profileData?.images?.length < 3 && <div className="img-tab ps-2 d-flex">
                                                    {profileData?.images?.map((img) => (
                                                        <div className='img-tab' onClick={() => handleImageClick(img?.url)} >
                                                            <FallbackImage
                                                                src={img?.url}
                                                                alt={"imgae"}
                                                                fallbackSrc={imgs}
                                                                style={{ width: "100px", height: "70px", objectFit: "cover", borderRadius: "5px" }}
                                                            />
                                                        </div>
                                                    ))}

                                                </div>}
                                                {profileData?.images?.length > 3 && <div className="img-tab ps-2">
                                                    <Slider {...settings}>
                                                        {profileData?.images?.map((img) => (
                                                            <div className='img-tab' onClick={() => handleImageClick(img?.url)} >
                                                                <FallbackImage
                                                                    src={img?.url}
                                                                    alt={"imgae"}
                                                                    fallbackSrc={imgs}
                                                                    style={{ width: "100px", height: "70px", objectFit: "cover", borderRadius: "5px" }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Slider>

                                                </div>}
                                            </div>}
                                        </>
                                    }
                                    {activeDiv === 'div2' &&
                                        <>
                                            {/* Main Video Section */}
                                            <div className="row m-0 d-flex justify-content-center">
                                                <div className="col-12 p-0 d-flex justify-content-center">
                                                    <div className="video-box my-4" style={{ height: '334px', width: '100%' }}>
                                                        {isYouTubeVideo(activeVideo) ? (
                                                            <iframe className="w-100 h-100" src={getEmbedUrl(activeVideo)} title="1 Kanal Ultra Luxury Full Furnished House for SALE in DHA Lahore By Pakistan Property" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
                                                        ) : (
                                                            <video
                                                                src={activeVideo}
                                                                controls
                                                                autoPlay
                                                                className="w-100 h-100"
                                                                style={{ objectFit: 'cover' }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {profileData?.video_links?.length > 1 && <div className="main-img-box">

                                                {profileData?.video_links?.length <= 3 &&
                                                    <div className="img-tab d-flex ps-2">
                                                            {profileData?.video_links?.map((img) => (
                                                                <div className='img-tab' onClick={() => handleVideoClick(img)} >
                                                                    <FallbackImage
                                                                        src={videoImg}
                                                                        alt={"imgae"}
                                                                        fallbackSrc={videoImg}
                                                                        style={{ width: "100px", height: "70px", objectFit: "cover", borderRadius: "5px" }}
                                                                    />
                                                                </div>
                                                            ))}

                                                    </div>
                                                }
                                               {profileData?.video_links?.length >3&& <div className="img-tab ps-2">
                                                    <Slider {...settings}>
                                                        {profileData?.video_links?.map((img) => (
                                                            <div className='img-tab' onClick={() => handleVideoClick(img)} >
                                                                <FallbackImage
                                                                    src={videoImg}
                                                                    alt={"imgae"}
                                                                    fallbackSrc={videoImg}
                                                                    style={{ width: "100px", height: "70px", objectFit: "cover", borderRadius: "5px" }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Slider>

                                                </div>}
                                            </div>}
                                        </>
                                    }
                                    {activeDiv === 'div3' &&
                                        <div className="row d-flex justify-content-center m-0">
                                            <div className="map-box my-4 px-0">
                                                <iframe title="map" src={`https://maps.google.com/maps?q=${profileData?.location?.latitude},${profileData?.location?.longitude}&z=15&output=embed`} width="100%" height="400" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-6 pe-4 pt-2">
                                <div className="gallery-input-card p-0 m-0">
                                    <div className="price">{profileData?.currency}{formatPrice(profileData?.price ? profileData?.price : 0)}</div>
                                    <button onClick={()=>handleOpen()} className='call-btn mt-2 mb-3'><FaPhoneAlt style={{ marginRight: "8px", fontSize: "10px" }} /> Call</button>
                                    <div className="company d-flex">
                                        <FallbackImage
                                            src={profileData?.user?.avatar ? profileData?.user?.avatar : img}
                                            alt={"...."}
                                            fallbackSrc={img}
                                            style={{width:"65px"}}
                                        />
                                        <div className="details mx-2">
                                            <div className="name">{profileData?.user?.name}</div>

                                            <div className="owner">{profileData?.user?.email}</div>
                                        </div>
                                    </div>
                                    <hr className='mb-0' />
                                    <div className="input-box px-2">
                                        <div className='input-label'>Name</div>
                                        <input type="text"
                                            className={`form-control input ${error && !data.name ? "is-invalid" : ""}`} placeholder="Name" value={data.name}
                                            onChange={(e) => setData({ ...data, name: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.name ? "Name is required" : ''}</i></p>
                                        <div className='input-label'>Email</div>
                                        <input type="email" className={`form-control input ${error && !data.email ? "is-invalid" : ""}`} placeholder="Email"
                                            value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.email ? "Email is required" : ''}</i></p>
                                        <div className='input-label'>Contact</div>
                                        <div className="input-group flex-nowrap d-flex align-items-center">
                                            <span className="input-group-text number" id="addon-wrapping"><img style={{ marginRight: "8px" }} src={flag} alt="..." />
                                                +92 <IoMdArrowDropdown /></span>
                                            <input type="number" className={`form-control input ${error && !data.contact ? "is-invalid" : ""}`} placeholder="e.g 3324751060"
                                                value={data.contact} onChange={(e) => setData({ ...data, contact: e.target.value })} />
                                        </div>
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.contact ? "Contact number is required" : ''}</i></p>
                                        <div className='input-label'>Message</div>
                                        <textarea type="text" className={`form-control input message-input ${error && !data.message ? "is-invalid" : ""}`} placeholder="Message"
                                            value={data.message} onChange={(e) => setData({ ...data, message: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.message ? "Message is required" : ''}</i></p>
                                        <div className="option d-flex justify-content-around pe-4 ps-0 my-2">
                                            <div className="text">I am a:</div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input" type="radio" name="radioGroup" id="exampleRadios1" value="Buyer/Tenant" checked={radio === 'Buyer/Tenant'}
                                                    onChange={(e) => { handleRadioChange(e); setData({ ...data, radio: e.target.value }); }} />
                                                <label className="form-check-label" htmlFor="exampleRadios1">
                                                    Buyer/Tenant
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="exampleRadios2" value="Agent" checked={radio === 'Agent'}
                                                    onChange={(e) => { handleRadioChange(e); setData({ ...data, radio: e.target.value }); }} />
                                                <label className="form-check-label" htmlFor="exampleRadios2">
                                                    Agent
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="exampleRadios3" value="Other" checked={radio === 'Other'}
                                                    onChange={(e) => { handleRadioChange(e); setData({ ...data, radio: e.target.value }); }} />
                                                <label className="form-check-label" htmlFor="exampleRadios3">
                                                    Other
                                                </label>
                                            </div>
                                        </div>
                                        <p className='alert-msg px-2 my-1'><i>{error && radio === '' ? "Please select an option" : ''}</i></p>
                                        <input
                                            type="text" className={`form-control input ${error && radio === 'Other' && !data.other ? "is-invalid" : ""}`}
                                            disabled={radio !== 'Other'} placeholder="Other" value={data.other} onChange={(e) => setData({ ...data, other: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && radio === 'Other' && !data.other ? "Other info is required" : ''}</i></p>
                                        <div className="form-check last-check my-3">
                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={checkbox}
                                                onChange={handleCheckbox} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Keep me informed about similar properties
                                            </label>
                                        </div>
                                    </div>
                                    <button className="send-btn my-1" onClick={handleSubmit} disabled={!checkbox} >
                                        Send Email
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default Card;