import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Footer from '../Camponent/NavBarFooter/Footer'

function PrivacyPolicy() {
  return (
    <div>
      <NavBar />
      <div className='privce-policeys my-5'>
        <h2 style={{ textAlign: 'center' }}>Privacy Policy</h2>
        <div className='priWill-policy  m-4'>
          <div className='privacy-policy'>

          </div>
          <div className='container-fluid p-4'>
            <div className='row m-0'>
              <div className='policy '>
                <h3>Who Will See Your Personal Information</h3>
                <p>Your personal information, including your name, address, and any other identifying details you provide, may be processed by PakistanProperty.com.  </p>
                <p>We may also share your information with the following:</p>
                <ul>
                  <li><span>Third-Party Service Providers:</span> We may engage trusted third parties to assist us in delivering our products and services, such as payment processors, marketing agencies, or website hosting providers.</li>
                </ul>
                <ul>
                  <li><span>Business Partners:</span> We may share your information with select business partners to enhance our services and provide you with additional value.</li>
                </ul>
                <ul>
                  <li><span>Authorized Third Parties:</span> With your explicit consent, we may share your information with approved third parties, such as social media platforms you choose to link your account to or third-party payment providers.</li>
                </ul>
                <p>Rest assured, we require all third parties to uphold the security of your personal data and handle it in strict compliance with applicable laws and regulations.</p>
                <p>We may also use or disclose your information if legally obligated to do so, enforce any agreement with you, or protect our rights and those of our affiliates.</p>
                <p>In certain circumstances, we may need to share some personal information with our affiliates, subsidiaries, third parties (like real estate agents using our platform), or during a business restructuring. We strive to anonymize personal information whenever possible, but this may not always be feasible.</p>
                <h3>Purpose of Processing Information</h3>
                <p>Your information allows us to provide access to relevant sections of PakistanProperty.com and deliver the services you need. </p>
                <p>We will use your information for the following purposes:</p>
                <ul>
                  <li><span>Personalized Experience:</span> Tailor your platform experience and ensure you see relevant property listings and content.</li>
                  <li><span>Service Delivery:</span> Facilitate your use of our services, including property searches, inquiries, and transactions.</li>
                  <li><span> Communication: </span>Inform you about special offers, updates, and relevant marketing information that may interest you.</li>
                  <li><span> Customer Research & Development:</span> Analyze user behavior and preferences to enhance our platform and services continually.</li>
                  <li> Feedback & Improvement:  Seek your valuable feedback on PakistanProperty.com, our services, and overall business.</li>
                  <li><span> Notification:</span> Keep you informed about changes or developments related to our platform, services, and company.</li>

                </ul>
                <p>We are committed to processing your information responsibly and transparently, ensuring it aligns with the purposes stated above and applicable privacy laws.</p>
                <h3>Disclosure of Information</h3>
                <p>In the unlikely event of a liquidation, administration, or receivership involving us or any part of our assets, the appointed insolvency practitioner may transfer your information to a third-party purchaser of the business. However, this transfer will only occur if the purchaser commits to using your information for the same purposes outlined in this privacy policy.</p>
                <p>We are committed to safeguarding your personal information and will not provide it to third parties except as described in this policy. </p>
                <p>Your information will not be disclosed to government or local authorities or other government institutions unless required by law or other binding regulations.</p>
                <p>Your privacy is important to us, and we take all necessary measures to protect your information.</p>
                <h3>The Data We Collect About You</h3>
                <p>When you visit PakistanProperty.com, use our Application, or create an account to access our Services, you may be asked to provide information about yourself.</p>
                <p> This might include:</p>
                <ul>
                  <li><span>Personalized Experience:</span> Tailor your platform experience and ensure you see relevant property listings and content.</li>
                  <li><span>Service Delivery:</span> Facilitate your use of our services, including property searches, inquiries, and transactions.</li>
                  <li><span> Communication: </span>Inform you about special offers, updates, and relevant marketing information that may interest you.</li>
                  <li><span> Customer Research & Development:</span> Analyze user behavior and preferences to enhance our platform and services continually.</li>
                  <li> Feedback & Improvement:  Seek your valuable feedback on PakistanProperty.com, our services, and overall business.</li>
                  <li><span> Notification:</span> Keep you informed about changes or developments related to our platform, services, and company.</li>
                </ul>
                <p>By using our platform, you agree to the collection of information about your usage and any messages or communications you send us.</p>
                <p>This information is essential for providing you with our Services. If you choose not to provide it, it may hinder or prevent us from fulfilling your requests.</p>
                <p>This privacy policy also applies when you access our platform or services through mobile devices like phones or tablets. Unless you've opted for anonymity through your device settings, we may automatically collect and use this information when you engage with our Services.</p>
                <p>Please note that calls and text messages between you and PakistanProperty.com, or between you and third parties (such as real estate agents) facilitated through our platform, may be recorded or monitored. This is done for quality assurance and to ensure excellent customer service.</p>
                <h3>Cookies and Information Sharing</h3>
                <p>When you visit PakistanProperty.com, we may send a small file called a "cookie" to your computer or device. This enables us to recognize you, track your activity on our platform, and understand your areas of interest. By doing so, we can personalize and enhance your browsing experience.</p>
                <p>Cookies may be used to collect and store personal data, and we might link this information with personal data you provide to us. You have the option to configure your browser to reject cookies, but this could limit your access to certain features on our platform.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
