import React, { useEffect, useState } from 'react'
import img from '../../Images/dummy/placeholder.png'
// import img1 from '../../Images/Agents/Rectangle 1813 (4).png'
// import img2 from '../../Images/Agents/Rectangle 1813 (2).png'
// import img3 from '../../Images/Agents/Rectangle 1813 (3).png'
import img4 from '../../Images/Agents/Frame 1300192747.png'
import img5 from '../../Images/Agents/Frame 1300192750.png'
import { IoLocationOutline } from 'react-icons/io5'
import { useAuth } from '../../Context/ContextProvider'
import { FallbackImage } from '../FallbackImage'
import { Link, useNavigate } from 'react-router-dom'
import { GoArrowUpRight } from 'react-icons/go'
import NoReacodFound from '../NavBarFooter/NoReacodFound'
function ThirdSection() {
    const { featureAgency ,loading} = useAuth()
    const [featureAgencyList, setFeatureAgencyList] = useState([])
    useEffect(() => {
        const fetchCart = async () => {
            try {
                let result = await featureAgency();
                if (result?.success) {
                    let propertyData = result?.data?.data;
                    setFeatureAgencyList(propertyData)
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };

        fetchCart()
        // eslint-disable-next-line
    }, [])
    const navigate=useNavigate()
    return (
        <>
            <div className="agents conatiner-fluid px-4 mt-3">
                <div className="row my-4 pt-3">
                    <div className="col-10">
                        <div className="agents-featured">
                            Featured Agencies
                        </div>
                    </div>
                </div>
                {loading? <div className='row'>
                 {[...Array(4)].map((_, colIndex) => (
      <div className="col-3 my-2" key={colIndex}>
        <span className="placeholder" style={{ width: "100%", height: "100px", borderRadius: "5px" }}></span>
      
      </div>
    ))}

                </div>:<div className="row m-0 px-1">
                    {featureAgencyList?.length ? featureAgencyList?.map((item) => {
                       return(
                        <div style={{cursor:"pointer"}} className="col-xl-3 col-lg-4 col-md-6 col-8 special-col p-0">
                        <div onClick={()=>navigate(`/agent-profile/${item?.id}`)} className="card-1">
                            <div className="row m-0">
                                <div className="col-3 p-0 d-flex align-items-center">
                                    <div className="card-img h-100">
                                        <FallbackImage style={{width:"100%",height:"100%",objectFit:"contain",borderRadius:"5px"}} src={item?.agency_profile?.agency_image} alt={"agency ptofile"} fallbackSrc={img} />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-1">
                                        <div className="first-heading py-0">
                                           {item?.agency_profile?.agency_name}
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">{item?.sell_count} for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">{item?.rent_count} for Rent</div>
                                            </div>
                                        </div>
                              <Link className='link mx-2'>View Agency Properties <GoArrowUpRight/></Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                       )
                    }) :
                    <NoReacodFound/>
                    }

                    {/* <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-md-0 mt-3 mb-0">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img1} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                        Madina Estate Advisor 
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-lg-0 mt-3 mb-0">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img2} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-xl-0 mt-3 mb-0">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img3} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
           
             
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 my-xl-3 mt-3 mb-0 p-0 special-col">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-3">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img1} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                        Madina Estate Advisor 
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-xl-3 mt-0 mb-3">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img2} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-xl-3 mt-0 mb-3">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img3} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>}
            </div>
        </>
    )
}

export default ThirdSection
