import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/Faqs/Banner'
import Footer from '../Camponent/NavBarFooter/Footer'

function Faqs() {
  return (
    <div>
      <NavBar/>
      <Banner/>
      <Footer/>
    </div>
  )
}

export default Faqs
