import React, { useEffect, useState } from 'react'
import img from '../../Images/active-icon.svg'
import img1 from '../../Images/rent-icon.png'
import img2 from '../../Images/sell-icon.png'
import img3 from '../../Images/superhot-icon.png'
import img5 from '../../Images/hot-icon.png'
import TableDashboard from '../Component/Dashboard/TableDashboard'
import DashBoardState from '../Component/Dashboard/DashBoardState'
import { useAuth } from '../../Context/ContextProvider'
import Slider from 'react-slick'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
function DashBoard() {
    const { getQoutaState, getProductList, getDashboradAnalaytics, getDashboradListing } = useAuth()
    const [productLogs, setproductLogs] = useState([])
    const [header, setHeader] = useState([])
    const [header1, setHeader1] = useState([])
    const [listindData, setListingData] = useState({})
    const [analytics, setAnalytics] = useState({})

    useEffect(() => {
        const fetchqouta = async () => {
            try {
                let result = await getQoutaState();

                if (result?.success) {
                    setproductLogs(result?.data?.data?.offer_logs);

                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchListing = async () => {
            try {
                let result = await getDashboradListing();

                if (result?.success) {
                    setListingData(result?.data?.data?.listings);

                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchProduct = async () => {
            try {
                let result = await getProductList();
                if (result?.success) {
                    let product = result?.data?.data?.products
                    const listingsHeaders = product.filter(header => header.type === 'listings');
                    const creditsHeaders = product.filter(header => header.type === 'credits');

                    setHeader(listingsHeaders)
                    setHeader1(creditsHeaders)

                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchAnalytics = async () => {
            try {
                let result = await getDashboradAnalaytics();
                if (result?.success) {
                    setAnalytics(result?.data?.data?.analytics)

                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        fetchProduct();
        fetchqouta()
        fetchListing()
        fetchAnalytics()

    }, [])
    const ProductCard = ({ product, log }) => (
        <div style={{ marginRight: "1rem" }} className='credits_box'>
            <h6 className='m-0 p-0'>{product.name} ({log?.total || 0})</h6>
            <div className='m-0 d-flex justify-content-between'>
                <p className='m-0'>Available</p>
                <p className='m-0'>{log?.available || 0}</p>
            </div>
            <div className='m-0 d-flex justify-content-between'>
                <p className='m-0'>Used</p>
                <p className='m-0'>{log?.used || 0}</p>
            </div>
            <div className='m-0 d-flex justify-content-between'>
                <p className='m-0'>Total</p>
                <p className='m-0'>{log?.total || 0}</p>
            </div>
        </div>
    );


    const PrevArrow = ({ onClick }) => (
        <button className="slick-prev slick-arrow" onClick={onClick}>
           <MdKeyboardArrowLeft style={{fontSize:'26px'}} />
        </button>
    );

    const NextArrow = ({ onClick }) => (
        <button className="slick-next slick-arrow" onClick={onClick}>
           <MdKeyboardArrowRight style={{fontSize:'26px' , backgroundColor:'transparent'}} />
        </button>
    );

    const settings = {
        dots: false, // Remove dots
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <PrevArrow />, // Custom Prev Arrow
        nextArrow: <NextArrow />, // Custom Next Arrow
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    return (
        <div className='dashboard_main p-3'>
            <div className='row m-0'>
                <div className='col-lg-5 col-md-12 col-12 ps-0 pe-lg-4 pe-0 mb-4'>
                    <h5 className='heading'>Listings</h5>
                    <div className="scroll-box">

                        <div className='card_main_listing new_card_main_listing d-flex mb-lg-0 mb-1'>
                            <div className='dashboard_listing_page new_dashboard_listing_page w-100 row m-0'>
                                <div className="col-lg-4 col-sm-2 col-1 p-0">
                                    <div className='box'>
                                        <div className='icons'>
                                            <img src={img} alt='...' />
                                        </div>
                                        <div className="ms-2 mt-1">
                                            <p className='m-0 title'>Active</p>
                                            <p className='m-0 count'>{listindData?.active}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-2 col-1 p-0">
                                    <div className='box'>
                                        <div className='icons'>
                                            <img src={img1} alt='...' />
                                        </div>
                                        <div className="ms-2 mt-1">
                                            <p className='m-0 title'>For Rent</p>
                                            <p className='m-0 count'>{listindData?.rent}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-2 col-1 p-0">
                                    <div className='box'>
                                        <div className='icons'>
                                            <img src={img2} alt='...' />
                                        </div>
                                        <div className="ms-2 mt-1">
                                            <p className='m-0 title'>For Sale</p>
                                            <p className='m-0 count'>{listindData?.sale}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-2 col-1 p-0">
                                    <div className='box'>
                                        <div className='icons'>
                                            <img src={img3} alt='...' />
                                        </div>
                                        <div className="ms-2 mt-1">
                                            <p className='m-0 title'>Super Hot</p>
                                            <p className='m-0 count'>{listindData?.super_hot}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-2 col-1 p-0">
                                    <div className='box'>
                                        <div className='icons'>
                                            <img src={img5} alt='...' />
                                        </div>
                                        <div className="ms-2 mt-1">
                                            <p className='m-0 title'>Hot</p>
                                            <p className='m-0 count'>{listindData?.hot}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-7 col-md-12 col-12 pe-0 ps-0 mb-lg-0 mb-5'>
            <div className="qouta_main">
                <h5 className='heading'>Quota and Credits</h5>
                <Slider {...settings}>
                    {header.map((product) => {
                        const log = productLogs?.find((log) => log.name === product.name);
                        return <ProductCard key={product.id} product={product} log={log} />;
                    })}
                    {header1.map((product) => {
                        const log = productLogs?.find((log) => log.name === product.name);
                        return <ProductCard key={product.id} product={product} log={log} />;
                    })}
                </Slider>
            </div>
        </div>

            </div>
            <DashBoardState analytics={analytics} />
            <TableDashboard />
        </div>
    )
}

export default DashBoard