import React, { useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/Listingproperty/Banner'
import Listing from '../Camponent/Listingproperty/Listing'
import Map from '../Camponent/Blogsingle/Map'
// import Similar from './Similar'
import Footer from '../Camponent/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'

function Listingproperty() {
  const {propertyList,data,setData}=useAuth()
  const [pagination,setPagination]=useState("")
  const [city_value, setCityValue] = useState({
    city: "",
    location: ""
  })
  const [property_name,setPropertyName]=useState()
  return (
    <div>
      <NavBar/>
      <Banner setPropertyName={setPropertyName} data={data} setData={setData} setPagination={setPagination} setCityValue={setCityValue} city_value={city_value} />
      <Listing property_name={property_name}  city_value={city_value} data={propertyList} pagination={pagination} filterData={data}/>
      <Map/>
      {/* <Blogheading/> */}
      {/* <div className='px-4'>
      <Similar/>
      </div> */}
      <Footer/>
    </div>
  )
}
export default Listingproperty
