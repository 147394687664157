import React, { useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { LuSave } from 'react-icons/lu'
import { useAuth } from '../../../Context/ContextProvider'
function ChangePassword() {
    const {changePassword,loading}=useAuth()
    const [password, setPassword] = useState({
        old: false,
        newPas: false,
        confimPas: false
    })
    const [data,setData]=useState({
        new_password:"",
        old_password:"",
        confim_password:"",
    })
    const [fieldErrors, setFieldErrors] = React.useState({})
    const validateStep = () => {
        let errors = {

        };
        if (!data.old_password) {
            errors.old_password = "Old Password is required.";
        }
        if (!data.confim_password) {
            errors.confim_password = "Confirm Password is required.";
        }
        if (!data.new_password) {
            errors.new_password = "New Password is required.";
        }
        else if (data.new_password.length < 8 || data.new_password.length > 10) {
            errors.new_password = "Password must be between 8 and 10 characters long.";
        } else if (!hasSpecialCharacter(data.new_password)) {
            errors.new_password = "Password must contain at least one special character.";
        }
        else if (data.new_password !== data.confim_password) {
            errors.confim_password = "Password not match";
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const hasSpecialCharacter = (password) => {
        // Regular expression for checking special characters
        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
        return specialCharacterRegex.test(password);
    };
    const submit =async (event) => {
        event.preventDefault();
        if (validateStep()) {
           await changePassword(data);
           
        }
    }
    return (
        <>
            <div className='locationPurpos'>
                <div className='row mt-3'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='inp_login'>
                            <label>Current Password</label>
                            <div style={{ position: "relative" }}>
                                <input onChange={(e)=>setData({...data,old_password:e.target.value})} type={`${password?.old ? "text" : "password"}`} placeholder='Password' />
                                {!password?.old ? <FaRegEyeSlash onClick={() => setPassword({...password,old:true})} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                    <FaRegEye onClick={() => setPassword({...password,old:false})} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                            </div>
                            {fieldErrors?.old_password && <span className='text_error'>{fieldErrors?.old_password}</span>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='inp_login'>
                            <label>New Password</label>
                            <div style={{ position: "relative" }}>
                                <input onChange={(e)=>setData({...data,new_password:e.target.value})} type={`${password?.newPas ? "text" : "password"}`} placeholder='Password' />
                                {!password?.newPas ? <FaRegEyeSlash onClick={() => setPassword({...password,newPas:true})} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                    <FaRegEye onClick={() => setPassword({...password,newPas:false})} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                            </div>
                            {fieldErrors?.new_password && <span className='text_error'>{fieldErrors?.new_password}</span>}

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='inp_login'>
                            <label>Confirm Password</label>
                            <div style={{ position: "relative" }}>
                                <input onChange={(e)=>setData({...data,confim_password:e.target.value})} type={`${password?.confimPas? "text" : "password"}`} placeholder='Password' />
                                {!password?.confimPas? <FaRegEyeSlash onClick={() => setPassword({...password,confimPas:true})} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                    <FaRegEye onClick={() => setPassword({...password,confimPas:false})} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                            </div>
                            {fieldErrors?.confim_password && <span className='text_error'>{fieldErrors?.confim_password}</span>}

                        </div>
                    </div>
                </div>
            </div>
            <div className='agency_setting my-3'>
                <div className='d-flex justify-content-end'>
                    <button onClick={submit}> {loading ? (
                            <div className="spinner-border text-light button_loading" role="status"></div>
                        ) : (
                            <>
                                Save Changes <LuSave />
                            </>
                        )}</button>
                </div>
            </div>
        </>
    )
}

export default ChangePassword