import React from 'react'

function PopularSearches() {
    return (
        <>
            <div className='similar mt-5 mb-3 pt-3'>
                <div className='row m-0' style={{boxShadow:"  0px 0px 4px 0px #00000040"}}>
                    <div className='col-lg-4 px-4 pt-2'>
                        <h3>Popular searches</h3>
                        <p>

                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4 px-4'>
                    <p className="Popular-searches-para" >
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4 px-4'>
                    <p className="Popular-searches-para" >
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                
                </div>
                <hr className='my-0 mx-4'/>
                <div className='row m-0' style={{boxShadow:"  0px 0px 4px 0px #00000040"}}>
                    <div className='col-lg-4 px-4 pb-2 pt-2'>
                        <h3>Related Links</h3>
                        <p>
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4 px-4 pb-2'>
                    <p className="Popular-searches-para" >
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4 px-4 pb-2'>
                    <p className="Popular-searches-para" >
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default PopularSearches
