import React, { useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HomePage/Banner'
import Instant from '../Camponent/InstantValue/Instant'
import PropertyVlaue from '../Camponent/InstantValue/PropertyVlaue'
import Footer from '../Camponent/NavBarFooter/Footer'


function InstantValue() {
  const [propertyList,setProperty]=useState([])
  const [propertyListAll,setPropertyAll]=useState([])
  return (
    <div>
      <NavBar/>
      <Banner setPropertyAll={setPropertyAll} setPropertyData={setProperty}/>
      <Instant/>
      <PropertyVlaue/>
      <Footer/>
    </div>
  )
}

export default InstantValue
