import React from 'react'
import img from '../../Images/Rectangle 1851.png'
import img1 from '../../Images/Rectangle 24.png'
import img2 from '../../Images/Rectangle 19.png'
import img3 from '../../Images/Rectangle 21.png'
function Banner() {
    return (
        <>
            <div className='news '>
                <div className='m-news px-4'>
                 
                </div>
                <div className='row m-0 px-4 mt-4 pt-1' >
                        <div className='col-lg-8 p-0'>
                            <div style={{ background: "white", padding: "1.1rem", borderRadius: '12px',marginTop:'1.8rem'  }}>
                                <div className='news-card' style={{ position: "relative" }}>
                                    <img src={img} style={{ width: "100%", borderRadius: '10px' }}  alt='...'/>
                                    <div className='new_card1 d-flex flex-column justify-content-between' style={{ position: "absolute", background: "rgba(0, 0, 0, 0.301)", width: "100%", height: "100%", top: "0", borderRadius: '10px', padding: '1.2rem' }}>

                                        <div className='news-dot'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div className='n-h'>
                                            <h3>Pakistan’s first world-class zoo, safari park project<br /> approved for ISB</h3>
                                            <div className='d-flex'>
                                                <h4 style={{ marginRight: '12px' }}>By Ginny Dennis</h4>
                                                <h4>Just now</h4>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='ne-heading'>
                                    <div className='ne-1'>
                                        <h3>Govt proposes Budget 2022-23 with PKR 9.8 tr outlay</h3>
                                        <h4>The new Netflix show “Cunk on Earth” looks like an ambitious BBC documentary. Until its<br /> fictional host, created by Charlie Brooker, starts to ask some deeply silly questions.</h4>
                                    </div>
                                    <div className='ne-2'>
                                        <img src={img1}  alt='...'/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-4 p-0 m-0'>
                            <div  className='row  m-0 p-1'>
                                <div className='col-lg-5'>
                                    <div style={{marginTop:'1.5rem'}} className='ns-heading '>
                                        <h4>Karachi: Karachi Relief Trust (KRT) has partnered with Bank Alfalah to fund the construction of low-cost, sustainable houses for the flood- sustainable houses for the flood-...  </h4>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div style={{ position: 'relative',marginTop:'1.5rem' }} className='nsh'>
                                        <img style={{ width: "100%" }} src={img2}  alt='...'/>
                                        <div style={{ position: 'absolute', top: "0" }} className='ns-img'>
                                            <div className='ns-h'>
                                                <h3>KRT to construct low-cost houses in flood-hit areas</h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='news-lower-section px-0'>
                                <div className='row m-0 p-0'>
                                    <div className='col-lg-4 p-0'>

                                        <div className='low'>
                                            <img src={img3}  alt='...'/>


                                        </div>



                                    </div>
                                    <div className='col-lg-1'>
                                        <div className='brd'>


                                        </div>
                                    </div>
                                    <div className='col-lg-7 '>
                                        <div className='punjab'>
                                            <h3>Punjab approves PKR 50bn for Apni Chat Apna Ghar, 8 othe projects</h3>
                                            <div className='punjab-span'>
                                                <div className='pu-span d-felx'>
                                                    <h4>By Ginny Dennis</h4>
                                                    <h5>Feb. 4, 2023</h5>
                                                </div>
                                                <p>As per the details, the approval came during the 10th   meet...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default Banner
