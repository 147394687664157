import React, { useEffect, useState } from 'react'
import { SlEqualizer } from 'react-icons/sl'
import img from '../../Images/dummy/placeholder.png'
import img2 from '../../Images/Square Meters.png'
import img3 from '../../Images/Frame 1300192801.png'
import img4 from '../../Images/Bath.png'
import img5 from '../../Images/Frame 1000014539.png'
import img6 from '../../Images/Vector.png'
import img7 from '../../Images/Frame 1000014540.png'
import img9 from '../../Images/Rectangle 748.png'
import Pagination from '@mui/material/Pagination';
import { BsGrid3X3GapFill, BsHouseDoorFill } from 'react-icons/bs'
import { FaList, FaRegSquare } from 'react-icons/fa'
import { MdOutlineArrowDropDown, MdOutlineBed } from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoCameraSharp, IoLocationSharp } from 'react-icons/io5'
import { IoMdCheckmark } from 'react-icons/io'
import { LuBath, LuFileWarning } from 'react-icons/lu'
import { PiGreaterThanThin } from 'react-icons/pi'
import { useAuth } from '../../Context/ContextProvider'
import Call from '../profile/Call'
import { FallbackImage } from '../FallbackImage'
import NoReacodFound from '../NavBarFooter/NoReacodFound'
function Listing({ pagination, data, city_value, filterData,property_name }) {
  const parms = useParams()
  const { loading, serchProperty,
    whatsappImpretion } = useAuth()
  const unitsToRemove = ["Square Yards", "Square Feet", "Square Meters"];
  function formatPrice(value) {
    if (value >= 10000000) {
     
      return (value / 10000000).toFixed(2) + ' Cr';
    } else if (value >= 100000) {
    
      return (value / 100000).toFixed(2) + ' Lakh';
    } else if (value >= 1000) {
     
      return (value / 1000).toFixed(2) + ' Thousand';
    } else {
      
      return value.toString();
    }
  }
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const [grid, setGrid] = useState(true)
  const handlePageChange = async (event, value) => {
    try {
      let results = await serchProperty({ ...filterData, page: value });
      if (results?.success) {
        
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } 
  };
  const [land_line, setland_line] = useState("")
  const AreaDisplay = ({ area }) => {
   
    const areaString = area?.toString() || "";
    const truncatedArea = areaString.length > 4 ? areaString.substring(0, 4) + "..." : areaString;
    return (
      <div>
        <span>{truncatedArea}</span>
      </div>
    );
  };

  const { featureAgency } = useAuth()
  const [featureAgencyList, setFeatureAgencyList] = useState([])
  useEffect(() => {
      const fetchCart = async () => {
          try {
              let result = await featureAgency();
              if (result?.success) {
                  let propertyData = result?.data?.data;
                  setFeatureAgencyList(propertyData)

              }
          } catch (error) {
              console.error("An error occurred while fetching data:", error);
          }
      };

      fetchCart()



      const checkScreenSize = () => {
        const screenWidth = window.screen.width;
        if (screenWidth <= 768) {
          setGrid(true);
        } else {
          setGrid(false);
        }
      };
  
      // Initial check on component mount
      checkScreenSize();
  
      // Listen to resize events
      window.addEventListener('resize', checkScreenSize);
  
      // Cleanup listener on component unmount
      return () => {
        window.removeEventListener('resize', checkScreenSize);
      };

      
  }, [])

  const navigate=useNavigate()
  function timeAgo(timestamp) {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);
    // Seconds
    if (diffInSeconds < 60) {
        return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
    }
    // Minutes
    if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    // Hours
    if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    // Days
    if (diffInSeconds < 2592000) { // 30 days
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    // Months
    if (diffInSeconds < 31536000) { // 365 days
        const months = Math.floor(diffInSeconds / 2592000);
        return `${months} month${months > 1 ? 's' : ''} ago`;
    }
    // Years
    const years = Math.floor(diffInSeconds / 31536000);
    return `${years} year${years > 1 ? 's' : ''} ago`;
}



  
  return (
    <>
      <div className='listing-property px-4'>
        <div className='row m-0'>
          <div className='d-flex p-0'>
            <div className='listing-mian  w-100 '>
              <div className='col-lg-8 d-flex mt-4'>
                <Link className='d-flex' style={{ fontSize: '14px', fontWeight: '500', fontFamily: 'var(--font-family)', color: '#1C9093', marginRight: '10px', textDecoration: "none", display: "flex", alignItems: "center" }} to={'/'}> Pakistan Property <PiGreaterThanThin className='ms-2' /></Link><span style={{ fontSize: '14px', fontWeight: '500', fontFamily: 'var(--font-family)', color: '#313839' }}>{property_name=== "Sell" ? "Buy" : property_name} {city_value?.city}</span>
              </div>
            </div>
          </div>
          <div className='col-lg-8 pt-1 p-0'>
            <div className='d-flex justify-content-between '>
              <h3 className='p-0 m-0'>Search Result: {data?.length}</h3>
              <div className='lis-icon pt-0'>
                {/* <span className='d-flex align-items-center'><SlEqualizer style={{ fontSize: "10px", marginRight: "5px" }} alt='...' /><p style={{ margin: "0" }}>Popular<MdOutlineArrowDropDown className='ms-1' /></p></span> */}
                <span className='jadoo' onClick={() => setGrid(false)} style={{ color: `${!grid ? '#1C9093' : ""}`, cursor: "pointer" }}><FaList /></span>
                <span className='jadoo' onClick={() => setGrid(true)} style={{ color: `${grid ? '#1C9093' : ""}`, cursor: "pointer" }}><BsGrid3X3GapFill /></span>
              </div>
            </div>
            {loading ? <div className='row'>
              <div className='col-4 my-2'>
                <span className='placeholder' style={{ width: "100%", height: "220px", borderRadius: "5px" }}></span>
              </div>
              <div className='col-8 my-2'>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
              </div>
              <div className='col-4 my-2'>
                <span className='placeholder' style={{ width: "100%", height: "220px", borderRadius: "5px" }}></span>
              </div>
              <div className='col-8 my-2'>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
              </div>
              <div className='col-4 my-2'>
                <span className='placeholder' style={{ width: "100%", height: "220px", borderRadius: "5px" }}></span>
              </div>
              <div className='col-8 my-2'>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>
                <span className='placeholder' style={{ width: "100%", height: "40px", marginTop: "15px", borderRadius: "5px" }}></span>

              </div>
            </div> :
              <div className='row m-0'>
                {data?.length ? <>
                  {!grid ? data?.map((item) => {
                    return (
                      <div style={{ borderRadius: '10px' }} className='least-card  listing p-0 my-2 col-12'>
                        <div className='row p-0 m-0'>
                          <div className='col-lg-4 p-0'>
                            <Link to={`/profile/${item?.id}`} style={{ textDecoration: "none", color: "#313839" }}>
                              <div style={{ position: "relative" }} className='lgos'>
                                <FallbackImage
                                  src={item?.images?.length ? item?.images[0]?.url : img}
                                  alt="Primary Image"
                                  className='list_img' style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: '5px 0 0 5px', }}
                                  fallbackSrc={img}
                                />
                                {item?.offer && item?.offer?.label !== "Listing" ? <div className="position-absolute  pt-1 px-3">
                                  {item?.offer?.label === "Super Hot Listing" ? <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
                                    <path d="M8 6.83272C8 8.68831 6.43395 10 4.22642 10C1.7217 10 4.76837e-07 8.43237 4.76837e-07 5.85466C4.76837e-07 1.83272 3.64152 0 6.29716 0C6.71698 0 6.92454 0.201087 6.92454 0.434175C6.92454 0.621573 6.82075 0.776952 6.6651 0.991767C6.28774 1.49451 5.64623 2.22577 5.64623 3.14443C5.64623 3.22212 5.65094 3.29983 5.66038 3.38208C5.92453 2.90219 6.39151 2.56398 6.95754 2.56398C7.11793 2.56398 7.20282 2.6554 7.20282 2.78792C7.20282 2.9479 7.1651 3.06216 7.1651 3.56033C7.1651 4.51553 8 5.15996 8 6.83272ZM4.13207 8.71573C5.07546 8.71573 5.70282 8.1627 5.70282 7.34004C5.70282 6.47623 5.07075 6.17002 4.98584 5.61243C4.98113 5.56672 4.94811 5.55301 4.9151 5.58501C4.67925 5.78153 4.53301 6.02833 4.4198 6.31171C4.39624 6.36198 4.3585 6.36654 4.33489 6.32542C4.07547 5.88666 4.0283 5.23309 4.08962 4.41043C4.09906 4.36472 4.06603 4.34186 4.02359 4.36014C2.90566 4.85375 2.33491 5.92779 2.33491 6.89214C2.33491 7.86107 2.92452 8.71573 4.13207 8.71573Z" fill="white" />
                                  </svg> :
                                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                                      <path d="M7.70351 5.06049C7.70456 5.78468 7.42766 6.50827 6.87537 7.06041C6.58287 7.35336 6.10828 7.35336 5.81533 7.06041C5.52239 6.76747 5.52239 6.29242 5.81533 5.99993C6.36808 5.44763 6.64437 4.72404 6.64347 4C6.64347 2.43807 6.01168 1.02344 4.98629 0C4.98629 1.17284 4.5117 2.23332 3.74311 2.99996L1.74318 4.99989C0.974294 5.76638 0.5 6.82701 0.5 7.99985C0.5 10.208 2.29188 11.9999 4.5 11.9999C6.70902 11.9999 8.5 10.208 8.5 7.99985C8.5 6.85206 8.1958 5.81243 7.70351 5.06049Z" fill="white" />
                                    </svg>
                                  }
                                  {item?.offer?.label?.replace("Listing", "")}
                                  <svg className='svg' xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <path d="M0 8L8 0H0V8Z" fill="#046B6D" />
                                  </svg>
                                </div> : ""}

                                <div className='listing-logos p-2'>

                                  <div className='lgs py-1'>
                                    {/* <div><FaHeart /></div>
                                  <div className='align'></div>
                                  <div><RiShareForwardFill /></div>
                                  <div className='align'></div> */}
                                    <div><IoLocationSharp /></div>
                                  </div>
                                </div>
                                <div className='lgs-icon p-2'>

                                  <div className='tick px-2'>
                                    <span><IoCameraSharp style={{ marginRight: '5px' }} alt='...' />{item?.images?.length}</span>
                                  </div>
                                  <div className='tick1 px-2'>
                                    <span><IoMdCheckmark /><span style={{ marginRight: '7px' }}>Verified</span></span>

                                  </div>

                                </div>
                              </div>

                            </Link>
                          </div>

                          <div className='col-lg-8 '>
                            <div className='py-3 px-2'>
                              <Link to={`/profile/${item?.id}`} style={{ textDecoration: "none", color: "#313839" }}>
                                <div className='last-heading'>
                                  <h2 style={{ padding: "0", margin: "0" }}>PKR {formatPrice(item?.price)}</h2>
                                  <h4 style={{ marginTop: '10px' }}>Added: {timeAgo(item?.created_at)}</h4>
                                </div>
                                <div className='b-heading'>
                                  <h2>{item?.title}</h2>
                                  {/* <span style={{ color: '#1C9093' }}><FaRegHeart /></span> */}
                                </div>
                                <h3>{item?.plot_number + " " + item?.location?.name + " " + item?.city?.name}</h3>
                                <div className='least-img'>
                                  <span>
                                    <img src={img2} alt='...' />
                                    <h4>{item?.area_size} {unitsToRemove.reduce((acc, unit) => {
                                      return acc.replace(unit, "");
                                    }, item?.unit_area || "")}</h4>
                                  </span>
                                  {item?.bedrooms !== "" && item?.bedrooms !== "undefined" ?<span>
                                  <MdOutlineBed style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{item?.bedrooms !== "" && item?.bedrooms !== "undefined" ? item?.bedrooms : 0} Beds
                                  </span>:""}
                                 {item?.bathrooms !== "" && item?.bathrooms !== "undefined" ? <span>
                                  <LuBath style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{item?.bathrooms !== "" && item?.bathrooms !== "undefined" ? item?.bathrooms : 0} Bathroom
                                  </span>:""}
                                </div>
                              </Link>
                              <hr />
                              <div className='rect-img'>
                                <div className='least-btn pb-2'>
                                  <a  href={`mailto:${item?.email}`}>
                                  <button><img src={img5} className='mx-1' alt='...' />Email</button>
                                  </a>
                                  <button onClick={() => {
                                    handleOpen()
                                    setland_line(item.user)
                                  }}><img src={img6} className='mx-1' alt='...' />Call</button>
                                  <a onClick={() => whatsappImpretion("whatsapp", item?.id)} href={`https://wa.me/${item?.contacts?.length ? item?.contacts[0] : ""}`} target="_blank" rel="noopener noreferrer">
                                    <button><img src={img7} className='mx-1' alt='...' />WhatsApp</button>
                                  </a>
                                </div>
                                {item?.is_pakistan_property ? <img src={img9} alt='...' /> : ""}

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    )
                  }) : data?.map((item) => {
                    return (
                      <div className=' col-sm-6 col-12 p-0 pe-2 my-2'>
                        <Link to={`/profile/${item?.id}`} style={{ textDecoration: "none", }}>
                          <div className="property-item rounded ">
                            <div className="position-relative">
                              <div style={{ width: "100%", overflow: "hidden", height: "250px" }}>
                              <FallbackImage
                                  src={item?.images?.length ? item?.images[0]?.url : img}
                                  alt="Primary Image"
                                 
                                  fallbackSrc={img}
                                />
                                {item?.offer && item?.offer?.label !== "Listing" ? <>
                                  <div style={{ whiteSpace: "nowrap" }} className="position-absolute  pt-1 px-3">{item?.offer?.label === "Super Hot Listing" ? <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
                                    <path d="M8 6.83272C8 8.68831 6.43395 10 4.22642 10C1.7217 10 4.76837e-07 8.43237 4.76837e-07 5.85466C4.76837e-07 1.83272 3.64152 0 6.29716 0C6.71698 0 6.92454 0.201087 6.92454 0.434175C6.92454 0.621573 6.82075 0.776952 6.6651 0.991767C6.28774 1.49451 5.64623 2.22577 5.64623 3.14443C5.64623 3.22212 5.65094 3.29983 5.66038 3.38208C5.92453 2.90219 6.39151 2.56398 6.95754 2.56398C7.11793 2.56398 7.20282 2.6554 7.20282 2.78792C7.20282 2.9479 7.1651 3.06216 7.1651 3.56033C7.1651 4.51553 8 5.15996 8 6.83272ZM4.13207 8.71573C5.07546 8.71573 5.70282 8.1627 5.70282 7.34004C5.70282 6.47623 5.07075 6.17002 4.98584 5.61243C4.98113 5.56672 4.94811 5.55301 4.9151 5.58501C4.67925 5.78153 4.53301 6.02833 4.4198 6.31171C4.39624 6.36198 4.3585 6.36654 4.33489 6.32542C4.07547 5.88666 4.0283 5.23309 4.08962 4.41043C4.09906 4.36472 4.06603 4.34186 4.02359 4.36014C2.90566 4.85375 2.33491 5.92779 2.33491 6.89214C2.33491 7.86107 2.92452 8.71573 4.13207 8.71573Z" fill="white" />
                                  </svg> :
                                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                                      <path d="M7.70351 5.06049C7.70456 5.78468 7.42766 6.50827 6.87537 7.06041C6.58287 7.35336 6.10828 7.35336 5.81533 7.06041C5.52239 6.76747 5.52239 6.29242 5.81533 5.99993C6.36808 5.44763 6.64437 4.72404 6.64347 4C6.64347 2.43807 6.01168 1.02344 4.98629 0C4.98629 1.17284 4.5117 2.23332 3.74311 2.99996L1.74318 4.99989C0.974294 5.76638 0.5 6.82701 0.5 7.99985C0.5 10.208 2.29188 11.9999 4.5 11.9999C6.70902 11.9999 8.5 10.208 8.5 7.99985C8.5 6.85206 8.1958 5.81243 7.70351 5.06049Z" fill="white" />
                                    </svg>
                                  }{item?.offer?.label?.replace("Listing", "")}</div>
                                  <svg className='svg' width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                                  </svg>
                                </> : ""}
                              </div>
                              {/* <a href="..." style={{border:"1px solid"}} className='overflow-hidden'></a> */}
                              {/* <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...' />POPULAR</div>
                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                            </svg> */}

                            </div>
                            <div className="p-4 pb-0">
                              <div className='d-flex justify-content-between'>
                                <h5 ><span>PKR</span> {formatPrice(item?.price)}
                                  {/* <span className='price_unit'>Crore</span> */}
                                </h5>
                                {/* <span style={{ border: "1px solid lightgray", height: "50px", width: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}><CiHeart style={{ fontSize: "25px", color: "#1C9093" }} /> </span> */}
                              </div>
                              <h5 className="d-block h5">{item?.title}</h5>
                              <p style={{ color: "gray" }}>{item?.plot_number + " " + item?.location?.name + " " + item?.city?.name}</p>
                            </div>
                            <div className="d-flex border-top property_small">
                              <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"><MdOutlineBed style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{item?.bedrooms !== "" && item?.bedrooms !== "undefined" ? item?.bedrooms : 0} Beds</small>
                              <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"><LuBath style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{item?.bathrooms !== "" && item?.bathrooms !== "undefined" ? item?.bathrooms : 0} Bathroom</small>
                              <small className="flex-fill text-center py-2 pe-2 d-flex justify-content-center align-items-center"><FaRegSquare style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{<AreaDisplay area={item?.area_size} />} {unitsToRemove.reduce((acc, unit) => {
                                return acc.replace(unit, "");
                              }, item?.unit_area || "")}</small>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </> :
                  <NoReacodFound/>
                }

              </div>}
            <div className='d-flex justify-content-center py-3'>
              {pagination !== 1 && <Pagination onChange={handlePageChange} count={pagination} color="primary" />}
            </div>
          </div>
          <div className='col-lg-4 pt-3'>
            {/* <div className='col-lg-4'>
              <h3 style={{ marginTop: '-5px', whiteSpace: "nowrap" }}>More about DHA Defence</h3>
            </div> */}
            <div className='l-s'>
              {/* <div className='row'>
                <div className='listing-img'>
                  <div className='col-lg-3'>
                    <img src={img10} alt='...' />
                  </div>
                  <div className='col-lg-3'>
                    <img src={img11} alt='...' />
                  </div>
                  <div className='col-lg-3'>
                    <img src={img12} alt='...' />
                  </div>
                  <div className='col-lg-3'>
                    <img src={img13} alt='...' />
                  </div>
                </div>
              </div> */}
              {/* <h4></h4>  */}
              {/* <div className='property-btn'>
                <button><BsHouseDoorFill className='me-1' style={{ color: 'black' }} alt='...' /> Property</button>
              </div>
              <div className='pro-div'>
                <div className='listing-div p-2'>
                  <div>DHA Phase (0)</div>
                  <div>DHA Phase (0)</div>

                  <div>DHA Phase (0)</div>
                  <div>DHA Phase (0)</div>

                  <div>DHA Phase (0)</div>
                  <div>DHA Phase (0)</div>

                  <div>DHA Phase (0)</div>
                  <div>DHA Phase (0)</div>

                  <div>DHA Phase (0)</div>
                  <div>DHA Phase (0)</div>

                  <div>DHA Phase (0)</div>
                  <div>DHA Phase (0)</div>

                  <div>DHA Phase (0)</div>
                  <div>DHA Phase (0)</div>
                </div>


              </div> */}
              <h4>Featured Agencies</h4>
              {featureAgencyList?.length&&<div className='row'>
                {featureAgencyList?.map((item)=>{
                  return(
                    <div onClick={()=>navigate(`/agent-profile/${item?.id}`)} style={{cursor:"pointer"}} title={item?.agency_profile?.agency_name} className='col-3 m-0'>
                    <div className='ls-img '>
                    <FallbackImage style={{borderRadius:"5px",width:"100%"}} src={item?.agency_profile?.agency_image} alt={"agency ptofile"} fallbackSrc={img} />
                    </div>
                  </div>
                  )
                })}
              </div>}
            </div>
            <Call land_line={land_line} open={open1} setOpen={setOpen1} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Listing
