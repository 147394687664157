import React, { useEffect, useState } from 'react'
import img1 from '../../Images/dummy/placeholder.png'
import { CiLocationOn, CiSearch } from 'react-icons/ci'
import { BsShop } from 'react-icons/bs'
import { PiBuilding, PiBuildingOfficeLight, PiHouseLine } from 'react-icons/pi'
import { TbBuildingSkyscraper, TbSquareRotated } from 'react-icons/tb'
import { useAuth } from '../../Context/ContextProvider'
import { FallbackImage } from '../FallbackImage'
import NoReacodFound from '../NavBarFooter/NoReacodFound'
import { useNavigate } from 'react-router-dom'
import { IoCloseSharp } from "react-icons/io5";
function OurProject() {
    const {projecttype,projectData}=useAuth()
    const [buy, setBuy] = useState()
     const [search , setSearch] = useState(false)
    const [projectList,setProjectList]=useState([])
    useEffect(()=>{
        if(projecttype?.length){
            setBuy(projecttype[0].id)
            fetchData(projecttype[0].id)
        }
        // eslint-disable-next-line
    },[projecttype])
    const fetchData = async (id) => {
        try {
          let result = await projectData(id);
          if (result?.success) {
            const propertyTypes = result.data.data.projects;
            setProjectList(propertyTypes);
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
        }
      };
      function formatPrice(value) {
        if (value >= 10000000) {
            // Convert to Crore
            return (
                <>
                    {(value / 10000000).toFixed(2)} Cr
                </>
            );
        } else if (value >= 100000) {
            // Convert to Lakh
            return (
                <>
                    {(value / 100000).toFixed(2)} Lakh
                </>
            );
        } else if (value >= 1000) {
            // Convert to Thousand
            return (
                <>
                    {(value / 1000).toFixed(2)} Thousand
                </>
            );
        } else {
            // Less than 1000, show as is
            return value?.toString();
        }
    }
    const navigate=useNavigate()
      
    return (
        <div className='container-fluid main_banner_home'>
            <div className='popular_properties'>
                <h1 className='my-4'>Our Projects</h1>
                <div className='d-flex flex-wrap justify-content-between pop-input'>
                    <div className='btn_popular'>
                        <div className='d-flex' style={{width:'fit-content'}}>
                        {projecttype?.map((item)=>{
                            return(
                                <button onClick={() =>{
                                     setBuy(item?.id)
                                     fetchData(item?.id)
                                    }} style={{ background: `${item?.id===buy ? "white" : "none"}` ,border:`${item?.id===buy? "1px solid lightgray":"none"}`}} className='d-flex align-items-center'>{item?.name==="High Rise"?<TbBuildingSkyscraper className='me-1'/>:<PiHouseLine className='me-1'/>}{item?.name}</button>
                            )
                        })}
                        </div>
                        <div className='inp_populer d-md-none d-inline'>
          {search? 
          <IoCloseSharp style={{ fontSize: "23px", padding: "0", margin: "0 .2rem" }} onClick={() => setSearch(!search)} />
          :
        <CiSearch style={{ fontSize: "23px", padding: "0", margin: "0 .2rem" }} onClick={() => setSearch(!search)} />
      }
        </div>
                    </div>
                    <div className='inp_populer mt-md-0 mt-3 d-md-inline d-none'>
                        <CiSearch style={{ fontSize: "23px", padding: "0", margin: "0 .5rem" }} /><input className='p-0' placeholder='Search' type='search' />
                    </div>
                </div>
                <div className='new_search_inp_populer d-md-none' style={{display:`${search?'block':'none'}`}}> 
      <input className='p-0' placeholder='Search' type='search' />
      </div>
            </div>
            <div className='our_project'>
                <div className='row '>
                    {projectList?.length?projectList?.map((project) => {
                        return (
                            <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12  my-3'>
                                <div onClick={()=>navigate(`/project-detail/${project?.id}`)} className="property-item rounded">
                                    <div className="position-relative">
                                        <div style={{ width: "100%", overflow: "hidden" }}>
                                            <FallbackImage alt={'....'} src={project?.images} fallbackSrc={img1}/>
                                        </div>
                                        {/* <a href="..." style={{border:"1px solid"}} className='overflow-hidden'></a> */}
                                        <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...' />Trending</div>
                                        <svg className='svg' width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                                        </svg>

                                    </div>
                                    <div className='p-3'>
                                        <h4>{project?.project_title}</h4>
                                        <h6> PKR {formatPrice(project?.min_price)} to {formatPrice(project?.min_price)}</h6>
                                        <p><CiLocationOn className='me-2' style={{ fontSize: "18px",color:"#1C9093" }} />{project?.location?.name},{project?.city?.city}</p>
                                        <hr className='p-0 pb-1 m-0'/>
                                        <div className='card_footer d-flex justify-content-between'>
                                            <span><BsShop className='me-2' />  Shops</span>
                                            <span><PiBuildingOfficeLight className='me-2' />  Office</span>
                                            <span> <PiBuilding className='me-2' />Apartment</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }):<NoReacodFound/>}
                </div>
            </div>

        </div>
    )
}

export default OurProject
