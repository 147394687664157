import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/Agents/Banner'
import SecondSection from '../Camponent/Agents/SecondSection'
import ThirdSection from '../Camponent/Agents/ThirdSection'
import LastSection from '../Camponent/Agents/LastSection'
import Footer from '../Camponent/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'
import { useNavigate } from 'react-router-dom'

function Agents() {
  const { agencyList,type,cityData,setFeatureAgencyList } = useAuth()
  const navigate=useNavigate()
  const searchData = async (dataValue) => {
          
    try {
        let result = await agencyList(dataValue);
        if (result?.success) {
            let propertyData = result?.data?.data?.agencies;
            setFeatureAgencyList(propertyData)
            navigate('/agent-list')
        }
    } catch (error) {
        console.error("An error occurred while fetching data:", error);
    }
};
  return (
    <div>
      <NavBar/>
      <Banner searchData={searchData} type={type} allcity={cityData}/>
      <SecondSection/>
      <ThirdSection/>
      <LastSection/>
      <Footer/>
    </div>
  )
}

export default Agents
